import { reducer,userReducer,DeviseReducer } from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
    combineReducers,
} from 'redux';
import {
    createStore,
} from 'redux';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['reducer','userReducer']
}
const reducerPersistConfig = {
    key: 'data',
    storage: storage,
    blacklist: ['loader']
}

/*  REDUX STORE CONFIG */

const rootReducer = combineReducers({
    reducer: persistReducer(reducerPersistConfig, reducer),
    userReducer:userReducer,
    DeviseReducer:DeviseReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
    let store = createStore(persistedReducer)
    let persistor = persistStore(store)
    return { store, persistor }
}


/*  REDUX STORE CONFIG */