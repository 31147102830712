import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ModalMarkUp from './MarkUpModal';
import { DEVISE } from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux'

function Header(props) {
  const { useState, useEffect } = React;
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const [modal14, setmodal14] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const Devise = useSelector((state) => state.DeviseReducer);

  const toggle = nr => () => {
   
    setmodal14(!modal14);
  }

  return (
    <div>
      <div className="main-header container">
        <div className="d-flex">
          <div className="mr-3 d-flex align-items-center"> <i className="fas fa-user-cog"></i> <NavLink exact to={`/Backoffice/EditUser?hotelid=${props.code}`} className="Signin"> <p className="mb-0 ml-2 cursor-pointer">User Admin</p></NavLink></div>
          <div className="mr-3 d-flex align-items-center"> <i className="far fa-list-alt"></i> <NavLink exact to={`/Backoffice?hotelid=${props.code}`} className="Signin"> <p className="mb-0 ml-2 cursor-pointer">Find Booking</p></NavLink></div>
          {props.user.roles.indexOf("ROLE_PARTICULAR") == -1 && <div className="mr-3 d-flex align-items-center"> <i className="fas fa-percentage"></i> <p className="mb-0 ml-2 cursor-pointer" onClick={toggle(14)}>Markup</p> </div> }
        </div>
        <div className="d-flex">
          {props.user.roles.indexOf("ROLE_PARTICULAR") == -1 && <div className="mr-3 d-flex align-items-center credit">Your Credit &nbsp; <strong> {props.user.deposit} {Devise.devise}</strong></div>}  
          <select className="mr-2"><option selected>ENG</option><option>FR</option></select>
          <p className="mb-0 mr-2">Welcome {props.user.username}</p>
          <div className="mr-2 d-flex align-items-center"> <i className="fas fa-sign-out-alt"></i> <p className="mb-0 ml-2 cursor-pointer" >Logout</p></div>
        </div>
      </div>
      <div className={click ? "main-container " : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="nav-container container p-0">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <div className="d-flex justify-content-between menu-items">
              <li className="nav-item">

              </li>
              <li className="nav-item">

              </li>
            </div>
            <NavLink exact to={`/?hotelid=${props.code}`} className="nav-logo">
              <div>
                <img
                  className="logo"
                  src={process.env.REACT_APP_API_KEY_PROD+ props.logo}
                ></img>
              </div>
            </NavLink>
            <div className="d-flex justify-content-between menu-items">
              <li className="nav-item ">

              </li>
              <li className="nav-item">

              </li>
            </div>
          </ul>
        </div>
      </nav>
      {props.user.roles.indexOf("ROLE_PARTICULAR") == -1 && <ModalMarkUp ModalMarkUp={modal14} toggle={toggle}/> }
    </div>
  );
}

export default Header;
