import "../../../App.css";
import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import jwt_decode from "jwt-decode";
import { Spinner, Button } from 'react-bootstrap';
import { LOGEDUSER } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";

const mapDispatchToProps = {
    LOGEDUSER,
};

function mapStateToProps(state) {
    return {
        data: state.userReducer
    }
}

class ResetPassword extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({
            messages: {
                firstname: 'Some message',
            },
        });
        this.state = {
            password: '',
            repassword: '',
            clickedbutton: false,
            message: '',
            redirect: false,
            token:'',
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var queryParams = new URLSearchParams(window.location.search)
        var token = queryParams.get("reset")
        var decoded = jwt_decode(token);
        if (decoded.exp < (Date.now() / 1000)) {
            this.setState({ redirect: true })
        }
        else {
            this.setState({ token: token })
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        var self = this;
        if (this.validator.allValid()  && (this.state.password==this.state.repassword)) {
            this.setState({
                clickedbutton: true,
            })
            fetch(`${process.env.REACT_APP_API_KEY_PROD}api/resetpassword/${this.state.token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password:this.state.repassword })
            })
                .then(function (response) {
                    if (!response.ok) {
                        self.setState({ message: 'error has accured', clickedbutton: false })
                        throw new Error("HTTP status " + response.status);
                    }
                    return response.json();
                })
                .then(
                    (result) => {
                        self.setState({
                            message: 'Your Password has been reset',
                            clickedbutton: false,
                        })
                    },
                    (error) => {
                        self.setState({ message: 'error has accured', clickedbutton: false })
                    }
                )

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        if (this.state.redirect) return (
            <Redirect to={`/?hotelid=${this.props.code}`} />
        )
        return (
            <div className="LoginApp">
                <div className="open resetpassff">
                    <div className="container login-row">
                        <div className="row w-100 justify-content-center">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="form form--login">
                                    <form className="js-form-gms-login">
                                        <div className="form__head">
                                            <div className="h5 form__title">Reset Password</div>
                                        </div>
                                        <div className="form__body">
                                            <p className="form__meta">* Required Fields</p>
                                            <div className="form__row">
                                                <label htmlFor="field-login-password" className="form__label">
                                                    Password *
                                                </label>
                                                <div className="form__controls">
                                                    <input
                                                        type="password"
                                                        className="field"
                                                        placeholder="Your Password *"
                                                        required=""
                                                        value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })}
                                                    ></input>
                                                    {this.validator.message('password', this.state.password, 'required|min:7')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__row">
                                            <label htmlFor="field-login-password" className="form__label">
                                                Confirm Password *
                                            </label>
                                            <div className="form__controls">
                                                <input
                                                    type="password"
                                                    className="field"
                                                    placeholder="Confirm Password *"
                                                    required=""
                                                    value={this.state.repassword} onChange={(e) => this.setState({ repassword: e.target.value })}
                                                ></input>
                                                {this.validator.message('repassword', this.state.repassword, 'required|min:7')}
                                            </div>
                                        </div>
                                        <div className="form__actions">
                                            {this.state.clickedbutton ?
                                                <Button disabled className="form__btn ">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button> :
                                                <button
                                                    type="submit"
                                                    className="form__btn "
                                                    value=""
                                                    onClick={(e) => this.submitForm(e)}
                                                >
                                                    SEND
                                                </button>
                                            }
                                            <div className="srv-validation-message text-center">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)