
import {
    combineReducers,
} from 'redux';
import { PURGE, REHYDRATE } from 'redux-persist';

/* INITIAL BOOKING ENGINE STATE */

const initialState = {
    loader: true,
    guests: [],
    arriving: '',
    departing: '',
    nights: '',
    rooms: 1,
    roomsbooked: [],
    name: '',
    email: '',
    phone: '',
    totalbooking: '',
    specialrequest: '',
    pax: '',
}

const UserState = {
    username: "",
    roles: [],
    email: "",
    loged: false,
    firstlastname: "",
    country: "",
    city: "",
    address: "",
    phone: "",
    deposit: "",
}

const DeviseState = {
    devise: "TND",
    exchange:'1.000',
}

/* INITIAL BOOKING ENGINE END */

/* STATE MUTATION */

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GUESTS':
            return {
                ...state,
                guests: action.payload
            }
        case 'DEPARTING':
            return {
                ...state,
                departing: action.payload
            }
        case 'ARRIVING':
            return {
                ...state,
                arriving: action.payload
            }
        case 'NIGHTS':
            return {
                ...state,
                nights: action.payload
            }
        case 'LOADER':
            return {
                ...state,
                loader: action.payload
            }
        case 'ROOMS':
            return {
                ...state,
                rooms: action.payload
            }
        case 'ROOMSBOOKED':
            return {
                ...state,
                roomsbooked: action.payload
            }
        case 'NAME':
            return {
                ...state,
                name: action.payload
            }
        case 'EMAIL':
            return {
                ...state,
                email: action.payload
            }
        case 'PHONE':
            return {
                ...state,
                phone: action.payload
            }
        case 'TOTALBOOKING':
            return {
                ...state,
                totalbooking: action.payload
            }
        case 'SPECIALREQUEST':
            return {
                ...state,
                specialrequest: action.payload
            }
        case 'PAX':
            return {
                ...state,
                pax: action.payload
            }
        default:
            return state;
    }
};

const userReducer = (state = UserState, action) => {
    switch (action.type) {
        case 'LOGEDUSER':
            return {
                ...state,
                username: action.payload.username,
                roles: action.payload.roles,
                email: action.payload.email,
                loged: action.payload.loged,
                firstlastname: action.payload.name,
                country: action.payload.country,
                city: action.payload.city,
                address: action.payload.address,
                phone: action.payload.phone,
                company: action.payload.company,
                deposit: action.payload.deposit,
            }
        case 'CREDIT':
            return {
                ...state,
                deposit: action.payload,
            }
        default:
            return state;
    }
};

const DeviseReducer = (state = DeviseState, action) => {
    switch (action.type) {
        case 'DEVISE':
            return {
                ...state,
                devise: action.payload.split('_')[0],
                exchange:action.payload.split('_')[1]
            }
        default:
            return state;
    }
};

/* STATE MUTATION */

export { reducer, userReducer,DeviseReducer };
