import "../../../App.css";
import React, { Component } from "react";
import jwt_decode from "jwt-decode";

class SignupSuccess extends Component {

    constructor() {
        super();
        this.state = {
            message: "",
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var self = this;
        var queryParams = new URLSearchParams(window.location.search)
        var token = queryParams.get("verification")
        var decoded = jwt_decode(token);
        fetch(`${process.env.REACT_APP_API_KEY_PROD}api/activateAccount/${decoded.email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(function (response) {
                if (!response.ok) {
                    self.setState({ message: 'an error has accured' })
                    throw new Error("HTTP status " + response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                    self.setState({ message: 'Thank you for joining our Journeys' })
                },
                (error) => {

                }
            )
    }

    render() {
        return (
            <div className="SignupSuccess">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <h1>{this.state.message}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SignupSuccess;