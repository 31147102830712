
/*  REDUX ACTIONS TO DISPATCH BOOKING  && VIEW EVENTS  */

export const GUESTS = payload => ({
    type: 'GUESTS',
    payload,
});

export const DEPARTING = payload => ({
    type: 'DEPARTING',
    payload,
});
export const ARRIVING = payload => ({
    type: 'ARRIVING',
    payload,
});
export const NIGHTS = payload => ({
    type: 'NIGHTS',
    payload,
});
export const LOADER = payload => ({
    type: 'LOADER',
    payload,
});
export const ROOMS = payload => ({
    type: 'ROOMS',
    payload,
});
export const ROOMSBOOKED = payload => ({
    type: 'ROOMSBOOKED',
    payload,
});
export const PHONE = payload => ({
    type: 'PHONE',
    payload,
});
export const EMAIL = payload => ({
    type: 'EMAIL',
    payload,
});
export const NAME = payload => ({
    type: 'NAME',
    payload,
});
export const LOGEDUSER = payload => ({
    type: 'LOGEDUSER',
    payload,
});
export const TOTALBOOKING = payload => ({
    type: 'TOTALBOOKING',
    payload,
});
export const SPECIALREQUEST = payload => ({
    type: 'SPECIALREQUEST',
    payload,
});
export const PAX = payload => ({
    type: 'PAX',
    payload,
});
export const CREDIT = payload => ({
    type: 'CREDIT',
    payload,
});
export const DEVISE = payload => ({
    type: 'DEVISE',
    payload,
});
/*  END  */