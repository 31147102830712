import React, { Component, useState } from "react";
import moment from "moment";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Spinner, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_test_51MGfytDcnsaCP3IqkDoTK2GF76Ysy7Zt8alFtESZ7YB5kJ9QB23DtVftL4sKpXUm53BzThB3gSupxALcogjkU7fk00TsZPQu00"
);

const PaymentForm = (props) => {
    let history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [clickedbutton, setclickedbutton] = useState(0);

  const handleSubmit =
    (stripe, elements, totalbooking, code, roomsbooked) => async () => {
      if (!props.validator()) {
      } else {
        setclickedbutton(true);
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          setclickedbutton(false);
        } else {
          const { id } = paymentMethod;
          fetch(
            `${process.env.REACT_APP_API_KEY_PROD}create-checkout-session/${totalbooking}/${code}`,
            {
              method: "POST",
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ id: id, rooms: roomsbooked }),
            }
          )
            .then(function (response) { return response.json(); })
            .then(function (json) {
              if (json[0].status=="succeeded") {
                fetch(
                  `${process.env.REACT_APP_API_KEY_PROD}api/hotelbooking/stripe/${props.code}`,
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json, text/plain, */*",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      arriving: moment(props.data.arriving).format(
                        "YYYY/MM/DD"
                      ),
                      departing: moment(props.data.departing).format(
                        "YYYY/MM/DD"
                      ),
                      pax: props.PAX,
                      rooms: props.data.roomsbooked,
                      devise: props.Devise.devise,
                      firstlastname: props.user.firstlastname,
                      email: props.user.email,
                      telephone: props.user.phone,
                      nationality: props.user.country,
                      address: props.user.address,
                      finalprice: props.data.totalbooking,
                      stripesessionkey: id,
                      nights: props.data.nights,
                      specialrequest: props.data.specialrequest,
                    }),
                  }
                )
                  .then(function (response) {
                    if (!response.ok) {
                      throw new Error("HTTP status " + response.status);
                    }
                    return response.json();
                  })
                  .then(
                    (result) => { history.push(`/backoffice/?hotelid=${props.code}`); },
                    (error) => {props.setMsgError("Reservation Error");}
                  );

                setclickedbutton(false);
              }else{
                props.setMsgError("Payment Error");
              }
            });
        }
      }
    };

  return (
    <>
      <CardElement options={{ hidePostalCode: true }} />
      <div className="leftbtnstripe">
        <div>
          <p>You pay whole sum for all nights.</p>
          <p>
            Credit card processing is performed by{" "}
            <a href="https://stripe.com/fr-us" target="_blank">
              <u>Stripe</u>
            </a>
            .
          </p>
        </div>
        {clickedbutton ? (
          <Button
            variant="primary"
            disabled
            className="style-1 button-stepper-availibity"
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Button>
        ) : (
          <input
            type="button"
            className="btn-primary style-1 button-stepper-availibity"
            onClick={handleSubmit(
              stripe,
              elements,
              props.totalbooking,
              props.code,
              props.roomsbooked
            )}
            value="BOOK"
          ></input>
        )}
      </div>
    </>
  );
};

const StripePaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <PaymentForm
      totalbooking={props.totalbooking}
      code={props.code}
      roomsbooked={props.roomsbooked}
      validator={props.validator}
      data={props.data}
      user={props.user}
      Devise={props.Devise}
      setMsgError={props.setMsgError}
      SPECIALREQUEST={props.SPECIALREQUEST}
      PAX={props.PAX}
    />
  </Elements>
);

export default StripePaymentForm;
