import "../../App.css";
import React, { Component } from "react";
import "../../flatpicker.css";
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import Loader from "../loader"
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { GUESTS, DEPARTING, ARRIVING, LOADER, NIGHTS, ROOMS, ROOMSBOOKED, TOTALBOOKING } from '../../redux/actions';
import { isMobile } from 'react-device-detect';

function mapStateToProps(state) {
  return {
    data: state.reducer,
    user: state.userReducer
  }
}

const mapDispatchToProps = {
  GUESTS,
  DEPARTING,
  ARRIVING,
  LOADER,
  NIGHTS,
  ROOMS,
  ROOMSBOOKED,
  TOTALBOOKING,
};

class Main extends Component {

  constructor(props) {
    var currentDate = new Date();
    var month = currentDate.getUTCMonth();
    var day = currentDate.getUTCDate();
    super();
    this.picker = React.createRef();
    this.container = React.createRef();
    /******* INITIAL COMPONENT MAIN STATES ******/
    this.state = {
      date: currentDate,
      click: false,
      adultes0: 1,
      children0: 0,
      baby0: 0,
      adultes1: 1,
      children1: 0,
      baby1: 0,
      adultes2: 1,
      children2: 0,
      baby2: 0,
      guests: 1,
      rooms: 1,
      data: [],
      monthfrom: moment().month(month).format("MMMM"),
      monthto: moment().month(month).add(1, 'day').format("MMMM"),
      dayto: moment().add(1, 'day').format('D'),
      dayfrom: day,
      nights: 1,
      arriving: moment().format('L'),
      departing: moment().add(1, 'day').format('L'),
      loading: false,
      redirect: false,
      inputClass: "",
    };
    /******* INITIAL COMPONENT MAIN STATES ******/
  }

  componentDidMount() {
    this.props.GUESTS([1, [0], 0]);
    this.props.ARRIVING(this.state.arriving);
    this.props.DEPARTING(this.state.departing);
    this.props.NIGHTS(this.state.nights);
    this.props.ROOMS(this.state.rooms);
    this.props.ROOMSBOOKED([]);
    this.props.TOTALBOOKING(0);
  }

  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        click: false,
      });
    }
  };

  /******* CLOSE MODAL EVENT ******/
  handleClick = () => {
    this.setState({
      click: !this.state.click,
    });
  };
  /******* CLOSE MODAL EVENT ******/

  /******* DATE PICKER EVENTS ******/
  OpenDatePicker = () => {
    this.picker.current.flatpickr.clear();
    this.picker.current.flatpickr.open();
  };

  onChangeDates = (selectedDates, dateStr, instance) => {
    if (dateStr && dateStr.split(" to ")[1] != undefined) {
      this.setState({
        monthfrom: moment().month(dateStr.split(" to ")[0].split("-")[1] - 1).format("MMMM"),
        monthto: moment().month(dateStr.split(" to ")[1].split("-")[1] - 1).format("MMMM"),
        dayfrom: dateStr.split(" to ")[0].split("-")[2],
        dayto: dateStr.split(" to ")[1].split("-")[2],
        nights: Math.abs(moment(dateStr.split(" to ")[0]).diff(moment(dateStr.split(" to ")[1]), 'days')),
        arriving: moment(dateStr.split(" to ")[0]).format('L'),
        departing: moment(dateStr.split(" to ")[1]).format('L'),
      });
    }
  }
  /******* DATE PICKER EVENTS ******/

  /******* GUESTS CHILDREN DROP DOWN STATE SELECTION ******/
  handleGuests = (name, value, modifier) => {
    if (value >= 0) {
      if (modifier == "plus") {
        this.setState({
          [name]: value + 1,
          guests: this.state.guests + 1,
        });
      } else {
        if (value != 0) {
          this.setState({
            [name]: value - 1,
            guests: this.state.guests - 1,
          });
        }
      }
    }
  };

  ChildrenAgeRender = (it) => {
    var divre = "";
    var content = [];
    for (let i = 0; i < it; i++) {
      divre = (
        <select
          name="age"
          data-group-child-age="0"
          aria-label="Âge de l'enfant n°&nbsp;1"
          className={this.state.inputClass + " sb-group-field-has-error"}
          onChange={(e) => {
            this.setState({
              ['agechild' + i]: parseInt(e.target.value),
            })
          }}
        >
          <option className="sb_child_ages_empty_zero" value="" selected="">
            Age Required
          </option>
          <option value="2">2 (y/o)</option>

          <option value="3">3 (y/o)</option>

          <option value="4">4 (y/o)</option>

          <option value="5">5 (y/o)</option>

          <option value="6">6 (y/o)</option>

          <option value="7">7 (y/o)</option>

          <option value="8">8 (y/o)</option>

          <option value="9">9 (y/o)</option>

          <option value="10">10 (y/o)</option>

          <option value="11">11 (y/o)</option>

          <option value="12">12 (y/o)</option>
        </select>
      );
      content.push(divre);
    }
    return content;
  };

  addRowRender = () => {

    var content = [];
    var rooms = "";
    for (let i = 0; i < this.state.rooms; i++) {
      rooms = (<div id={"ROOM" + i}>
        <p className="room-nbr d-flex">ROOM {i + 1} {i > 0 && <i className="gg-trash ml-3 mt-2" onClick={() => this.deleteRow()}></i>}</p>
        <ul className="ul-guest" >
          <li className="li-guest" key="1">
            <span className="guests-title">ADULTES</span>{" "}
            <span
              className="add-minusrow"
              onClick={() =>
                this.handleGuests(
                  "adultes" + i,
                  this.state["adultes" + i],
                  "plus"
                )
              }
            >
              +
            </span>
            <span>{this.state["adultes" + i]}</span>
            <span
              className="add-minusrow"
              onClick={() =>
                this.handleGuests(
                  "adultes" + i,
                  this.state["adultes" + i],
                  "minus"
                )
              }
            >
              -
            </span>
          </li>
          <li className="li-guest" key="2">
            <span className="guests-title">
              CHILDREN
              <br />
              <span className="guests-title-rec">
                From 2 to 12 (y/o)
              </span>
            </span>
            <span
              className="add-minusrow"
              onClick={() =>
                this.handleGuests(
                  "children" + i,
                  this.state['children' + i],
                  "plus"
                )
              }
            >
              +
            </span>
            <span>{this.state['children' + i]}</span>
            <span
              className="add-minusrow"
              onClick={() =>
                this.handleGuests(
                  "children" + i,
                  this.state['children' + i],
                  "minus"
                )
              }
            >
              -
            </span>
          </li>
          <li className="li-guest" key="3">
            <span className="guests-title">
              BABY <br />
              <span className="guests-title-rec">
                less than 2 (y/o)
              </span>
            </span>{" "}
            <span
              className="add-minusrow"
              onClick={() =>
                this.handleGuests("baby" + i, this.state['baby' + i], "plus")
              }
            >
              +
            </span>
            <span>{this.state['baby' + i]}</span>
            <span
              className="add-minusrow"
              onClick={() =>
                this.handleGuests(
                  "baby" + i,
                  this.state['baby' + i],
                  "minus"
                )
              }
            >
              -
            </span>
          </li>
        </ul>
        {this.state['children' + i] > 0
          ? this.ChildrenAgeRender(this.state['children' + i])
          : ""}
        {this.RenderElement(i)}

      </div>)
      content.push(rooms);
    };
    return content;
  };

  RenderElement = (i) => {
    let showclass = "style-1 addrow";
    if (i == 0 && this.state.rooms > 1) {
      showclass = "style-1 addrow d-none";
    }
    if (i == 1 && this.state.rooms == 2) {
      showclass = "style-1 addrow";
    }
    if (i == 1 && this.state.rooms > 2) {
      showclass = "style-1 addrow d-none";
    }
    if (i == 2) {
      showclass = "style-1 addrow d-none";
    }
    if (i == 2) {
      return '';
    } else {
      return (<div className="w-100 justify-content-end d-flex">
        <button type="submit" className={showclass} value="" name={"addrow-" + i} onClick={(e) => this.addRow(e)}>Add Room</button>
      </div>)
    }
  }

  addRow = (e) => {
    this.setState({
      rooms: this.state.rooms + 1,
    })
  }

  deleteRow = (e) => {
    this.setState({
      rooms: this.state.rooms - 1,
    })
  }

  /******* STATES MUTATIONS UPDATE FOR BOOKING IN REDUX &&  ******/
  recapGuestsAndRoomsRender = () => {
    var content = [];
    var recap = "";
    for (let i = 0; i < this.state.rooms; i++) {
      recap = (
        <div className="d-flex justify-content-around">
          <div><p className="nbrs-sh"> {this.state['adultes' + i]} ADULTES <br /> {this.state['children' + i]}  CHILDREN  <br />{this.state['baby' + i]}  Baby</p></div>

          <div className="devider"></div>
          <div className="d-flex align-items-center justify-content-center"><p className="rooms-sh-nbr ">ROOM {i + 1}</p></div>
        </div>)

      content.push(recap);
    }
    return content;
  }

  hotelAvailibility = () => {
    let Guests = [];
    for (var i = 0; i < this.state.rooms; i++) {
      let guest = [];
      guest.push(this.state['adultes' + i]);
      guest.push([this.state['children' + i]]);
      for (var j = 0; j < this.state['children' + i]; j++) {
        if (this.state['agechild' + j]) {
          guest[1].push(this.state['agechild' + j]);
        } else {
          this.setState({
            inputClass: "invalid"
          })
          return false;
        }
      }
      guest.push(this.state['baby' + i]);
      Guests.push(guest);
    }
    this.props.GUESTS(Guests);
    this.props.ARRIVING(this.state.arriving);
    this.props.DEPARTING(this.state.departing);
    this.props.NIGHTS(this.state.nights);
    this.props.ROOMS(this.state.rooms);
    this.props.LOADER(false);
    this.setState({
      redirect: true,
    })
  }

  /******* STATES MUTATIONS UPDATE FOR BOOKING IN REDUX &&  ******/

  render() {
    if (this.state.redirect) return (
      <Redirect to={`/choose-your-room?hotelid=${this.props.code}`} />
    )
    if (this.state.loading) return (
      <Loader />
    ); else {
      const { date } = this.state;
      return (
        <div className="App">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-4 col-md-4 col-6"
                onClick={this.OpenDatePicker}
              >
                <p className="title">Arriving</p>
                <div className="arrow-up"></div>
                <div className="datefield-container">
                <div id="von" className="dateField">
                  <p className="month"> {this.state.monthfrom} </p>
                  <p className="day">{this.state.dayfrom}</p>
                  <div className="border">
                    <span className="arrow"></span>
                  </div>{" "}
                </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 col-6" onClick={this.OpenDatePicker}>
                <p className="title">Departing</p>
                <div className="arrow-up"></div>
                <div className="datefield-container">
                <div id="von" className="dateField">
                  <p className="month"> {this.state.monthto}</p>
                  <p className="day">{this.state.dayto}</p>
                  <div className="border">
                    <span className="arrow"></span>
                  </div>{" "}
                </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-xl-4 col-md-4 col-12 guests-mb"
                style={{ cursor: "pointer" }}
              >
                <p className="title">Guests</p>

                <div className="arrow-up"></div>
                <div className="datefield-container">
                <div id="von" className="datef" onClick={this.handleClick} ref={this.container}>
                  <div className="d-flex justify-content-around">
                    <p className="month">GUESTS</p>
                    <p className="month">ROOMS</p>
                  </div>
                  {this.recapGuestsAndRoomsRender()}
                  <div className="border">
                    <span className="arrow"></span>
                  </div>{" "}
                </div>
                </div>
                <div className={this.state.click ? "dropdown-guest" : "d-none dropdown-guest"}>
                  {this.addRowRender()}
                  <div className="d-flex justify-content-center"><button type="button" className="addrow" onClick={() => this.setState({
                    click: !this.state.click,
                  })}>Confirm</button></div>
                </div>
              </div>
              <Flatpickr
                onClose={this.onChangeDates}
                className="vis-datepicker"
                ref={this.picker}
                options={isMobile ? { mode: "range", showMonths: "1", minDate: this.state.date } : { mode: "range", showMonths: "2", minDate: this.state.date }}
                value={date}
              />
              <div className="col-12">
                <p className="rooms-sh-nbr text-center">Your Stay :<span className="nightsNbr"> {this.state.nights} {this.state.nights == 1 ? "Night" : "Nights"} </span></p>
              </div>
              <div className="col-12">
                <div className="booking-button_wrapper">
                  <div className="btn-primary-container calendar">
                    <button
                      type="submit"
                      onClick={this.hotelAvailibility}
                      className="style-1 button-stepper"
                      value="Make a reservation"
                    >
                      Make a reservation
                    </button>
                  </div>
                </div>
         
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Main)