import "../../404.css";
import React, { Component } from "react";
import SVG from '../../img/404.svg'
function page404(props) {
    return (
        <div id="main">
                <img src={SVG}></img>
                <div className="fof">
                        <h1>We couldn't find the page you were looking for  <br></br> Maybe Our Faq or Community can help</h1>
                </div>
        </div>
        )
    }
export default page404;