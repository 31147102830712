import "../../../App.css";
import React, { Component } from "react";
import ReactFlagsSelect from 'react-flags-select';
import SimpleReactValidator from 'simple-react-validator';
import { Redirect } from "react-router-dom";
import { Spinner, Button } from 'react-bootstrap';
import IntPhone from '../../Components/signup_signin_pages/intphone'
import jwt_decode from "jwt-decode";
import { isValidPhoneNumber } from 'react-phone-number-input'


class Signup extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({
            messages: {
                firstname: 'Some message',
            },
        });
        this.state = {
            date: new Date(),
            selectedCountry: 'TN',
            name: '',
            username: '',
            email: '',
            password: '',
            address: '',
            phone: '',
            phonemsgerror: '',
            city: '',
            emailerror: '',
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    submitForm = (e) => {
        e.preventDefault();
        var self = this;
        if (this.validator.allValid() && isValidPhoneNumber(this.state.phone)) {
            this.setState({
                clickedbutton: true,
            })
            fetch(`${process.env.REACT_APP_API_KEY_PROD}api/signup/${this.props.code}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "name": this.state.name,
                    "username": this.state.username,
                    "email": this.state.email,
                    "password": this.state.password,
                    "country": this.state.selectedCountry,
                    "city": this.state.city,
                    "address": this.state.address,
                    "phone": this.state.phone,
                })
            })
                .then(function (response) {
                    if (!response.ok) {
                        self.setState({ errorlogin: 'invalid credentials', clickedbutton: false })
                        throw new Error("HTTP status " + response.status);
                    }
                    return response.json();
                })
                .then(
                    (result) => {
                        if (result == "email already exist") {
                            self.setState({ emailerror: 'Email Address already exist', clickedbutton: false })
                        } else {
                            self.setState({ clickedbutton: false })
                            window.location.href = `${process.env.REACT_APP_API_KEY_PROD_CURR}signup/Signupsuccess/?hotelid=${this.props.code}&account=${result.token}`;
                        }
                    },
                    (error) => {
                        self.setState({ emailerror: 'Email Address already exist', clickedbutton: false })
                    }
                )
        } else {
            if (this.state.phonemsgerror == "") {
                this.state.phonemsgerror = "Phone number required";
            }
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    phoneinputFn = (value) => {
        this.setState({
            phone: value
        })
    }
    render() {
        if (this.props.user.loged) return (
            <Redirect to={`/?hotelid=${this.props.code}`} />
        )
        return (
            <div className="signupApp">
                <div className="open">
                    <div className="container login-row">
                        <div className="row w-100">
                            <div className="col-lg-12">
                                <div className="form form--login w-100">
                                    <form action="#" method="post" className="js-form-gms-login">
                                        <div className="form__body">
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-50 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        First-Last name
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('First-Last name', this.state.name, 'required')}
                                                    </div>
                                                </div>
                                                <div className="form__row w-50">
                                                    <label className="form__label">
                                                        Username
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('username', this.state.username, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-50 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Email Address *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="email"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('email', this.state.email, 'required|email')}
                                                        <div className="app-field-message"><span>* Booking confirmations will be sent to this address.</span></div>
                                                        <div className="srv-validation-message">{this.state.emailerror}</div>
                                                    </div>
                                                </div>
                                                <div className="form__row w-50">
                                                    <label className="form__label">
                                                        Password *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="password"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('password', this.state.password, 'required|min:7')}
                                                    </div>
                                                    <div className="app-field-message"><span>* Your password must be between 6 and 17 characters and contain at leat one number</span></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-50 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Country *
                                                    </label>
                                                    <div className="form__controls">
                                                        <ReactFlagsSelect
                                                            selected={this.state.selectedCountry}
                                                            onSelect={code => this.setState({ selectedCountry: code })}
                                                            searchable
                                                            selectButtonClassName="country-input"
                                                            className="menu-flags"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__row w-50">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        City
                                                    </label>

                                                    <div className="form__controls pt-3">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('city', this.state.city, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-100 ">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Address *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            placeholder=""
                                                            required=""
                                                            value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('address', this.state.address, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-100">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        Phone *
                                                    </label>
                                                    <div className="form__controls">
                                                        <IntPhone phoneFn={this.phoneinputFn} phone={this.state.phone} phonemsgerror={this.state.phonemsgerror} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__actions">
                                            <div className="d-flex align-items-center">
                                                <input type="checkbox"></input>
                                                <p className="terms-and-conditions ml-2">You understand the rules of the Warwick Journeys terms and conditions. Your personal data will be processed for the creation and use of an account only. More information is available in our Privacy Notice.</p>
                                            </div>
                                            {this.state.clickedbutton ?
                                                <Button disabled className="form__btn ">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button> :
                                                <button
                                                    type="submit"
                                                    className="form__btn "
                                                    value=""
                                                    onClick={(e) => this.submitForm(e)}
                                                >
                                                    Become Partner
                                                </button>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}
export default Signup;

