import "../../../App.css";
import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import { connect } from 'react-redux';
import { LOADER, LOGEDUSER } from '../../../redux/actions';
import { loadStripe } from "@stripe/stripe-js";
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { Redirect } from "react-router-dom";
import Loader from "../../loader";

function mapStateToProps(state) {
    return {
        data: state.reducer,
        user: state.userReducer,
        Devise: state.DeviseReducer,
    }
}

const mapDispatchToProps = {
    LOADER,
    LOGEDUSER
};

class Bookingconfirm extends Component {
    constructor(props) {
        super();
        this.state = {
            params: window.location.search.slice(1).split('&').reduce((acc, s) => {
                const [k, v] = s.split('=')
                return Object.assign(acc, { [k]: v })
            }, {}),
            redirect: false,
        }
    }
    async componentDidMount() {
        var self = this;
        const stripePromise = loadStripe(
            "pk_test_51KLmynB1Z5vXcaQtUIcHXy7HO7Xcw99NRUu1quC3IrZuUcwAWLGjnE0vZr0v9mBR4ro2h795FA9e0GmeO1ythkAq002lxSzwVm"
        );
        const stripe = await stripePromise;
        fetch(`${process.env.REACT_APP_API_KEY_PROD}decode-checkout-session/${this.state.params.sessionid}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (session) {
                self.booking(session);
            })
            .then(function (result) {
            })
            .catch(function (error) {
            });
    }

    booking = (session) => {
        this.props.LOADER(true);
        fetch(`${process.env.REACT_APP_API_KEY_PROD}api/hotelbooking/stripe/${this.props.code}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "arriving": moment(this.props.data.arriving).format('YYYY/MM/DD'), "departing": moment(this.props.data.departing).format('YYYY/MM/DD'), "rooms": this.props.data.roomsbooked,"devise": this.props.Devise.devise,
                "firstlastname": this.props.user.firstlastname, "email": this.props.user.email, "telephone": this.props.user.phone, "nationality": this.props.user.country, "address": this.props.user.address,
                "finalprice": this.props.data.totalbooking, "stripesessionkey": this.state.params.sessionid, "nights": this.props.data.nights, "specialrequest": this.props.data.specialrequest, "pax": this.props.data.pax,
            })
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                    this.setState({
                        redirect: true,
                    })
                },
                (error) => {

                }
            )
    }

    render() {
        if (this.state.redirect) {
            this.props.LOADER(false);
            return <Redirect to={`/backoffice/?hotelid=${this.props.code}`} />
        }
        return (
            <Loader></Loader>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookingconfirm)