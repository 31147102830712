import "../../../App.css";
import React, { Component } from "react";
import "../../../flatpicker.css";
import Flatpickr from "react-flatpickr";
import DataTable from 'react-data-table-component';
import reactDom from "react-dom";
import { connect } from 'react-redux';
import moment from 'moment';
import { LOADER } from '../../../redux/actions';
import { Spinner, Button } from 'react-bootstrap';
import CancelModal from '../../Components/backoffice/cancelModal'

function mapStateToProps(state) {
    return {
        data: state.userReducer,
        user: state.userReducer
    }
}

const mapDispatchToProps = {
    LOADER,
};

class Backoffice extends Component {
    constructor() {
        super();
        this.state = {
            date: new Date(),
            booking: [],
            start: new Date(),
            end: new Date(),
            ref: '',
            type: 1,
            status: "All",
            referencet: '',
            clickedbutton1: false,
            clickedbutton2: false,
            show: false,
            modal2: false,
            cancelref: '',
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.LOADER(true);
        this.filterBooking(false);
    }
    togglecancel = (m, ref) => {
        this.setState({
            cancelref: ref,
            modal2: !this.state.modal2
        })
    }
    filterBooking = (type) => {

        if (type) {
            this.setState({
                clickedbutton1: true,
            })
            var data = JSON.stringify({
                "token": localStorage.getItem("token"), "reference": this.state.ref, "start": "", "end": "", "type": "", "status": "", "referencet": ""
            })
        } else {
            this.setState({
                clickedbutton2: true,
            })
            var data = JSON.stringify({
                "token": localStorage.getItem("token"), "start": this.state.start, "end": this.state.end, "reference": "", "type": this.state.type, "status": this.state.status, "referencet": this.state.referencet
            })
        }
        fetch(`${process.env.REACT_APP_API_KEY_PROD}api/ShowBookingHistory`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: data
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                    this.props.LOADER(false);
                    this.setState({
                        booking: result.data,
                        clickedbutton1: false,
                        clickedbutton2: false,
                    })
                },
                (error) => {
                    this.props.LOADER(false);
                    this.setState({
                        clickedbutton1: false,
                        clickedbutton2: false,
                    })
                }
            )
    }

    onChangeDates = (selectedDates, dateStr, instance) => {
        if (dateStr && dateStr.split(" to ")[1] != undefined) {
            this.setState({
                start: moment(dateStr.split(" to ")[0]).format('L'),
                end: moment(dateStr.split(" to ")[1]).format('L'),
            });
        } else {
            this.setState({
                start: moment(dateStr.split(" to ")[0]).format('L'),
                end: moment(dateStr.split(" to ")[0]).format('L'),
            });
        }
    }
    render() {
        
        var data = [];
        this.state.booking.map((e => {
            let credit = {
                bookingreference: Array.isArray(e) ? e[0].reference : e.reference,
                creationdate: Array.isArray(e) ? moment(e[0].createdAt).format('MMMM Do YYYY, HH:mm:ss') : moment(e.createdAt).format('MMMM Do YYYY, HH:mm:ss'),
                cancellationcharges: Array.isArray(e) ? moment(e[0].cancellationDate).format('MMMM Do YYYY, HH:mm:ss') : moment(e.cancellationDate).format('MMMM Do YYYY, HH:mm:ss'),
                passenger: Array.isArray(e) ? e[0].firstname : e.firstname,
                status: Array.isArray(e) ? e[0].status : e.status,
                stripe: Array.isArray(e) ? e[0].stripeSessionKey : e.stripeSessionKey,
                from: Array.isArray(e) ? moment(e[0].dateFrom).format('MMMM Do YYYY') : moment(e.dateFrom).format('MMMM Do YYYY'),
                to: Array.isArray(e) ? moment(e[0].dateTo).format('MMMM Do YYYY') : moment(e.dateTo).format('MMMM Do YYYY'),
                nbNight: Array.isArray(e) ? e[0].nbNight : e.nbNight,
                finalPrice: Array.isArray(e) ? e[0].invoice['priceTTC'] : e.invoice['priceTTC'],
                Devise: Array.isArray(e) ? e[0].devise : e.devise,
                nbadults: Array.isArray(e) ? e : e.nbAdults,
                nbchildren: Array.isArray(e) ? e : e.nbChildren[0],
                nbbaby: Array.isArray(e) ? e : e.nbbaby,
                hotelname: Array.isArray(e) ? e[0].idRoom['hotel']['name'] : e.idRoom['hotel']['name'],
                commission: Array.isArray(e) ? e[0].markup["markupAgency"] : e.markup["markupAgency"],
                passengers: Array.isArray(e) ? e : e.pax,
                board: Array.isArray(e) ? e : e.board,
                room: Array.isArray(e) ? e : e.idRoom['nameRoom'],
                fees: Array.isArray(e) ? e[0].cancellationFees : e.cancellationFees,
                cancellationdate: Array.isArray(e) ? moment(e[0].from).format('MMMM Do YYYY') : moment(e.from).format('MMMM Do YYYY'),
                cancellationbool: Array.isArray(e) ? moment(e[0].cancellationDate).isSameOrAfter(moment()) : moment(e.cancellationDate).isSameOrAfter(moment()),
            }
            data.push(credit);
        }));
        
        // A super simple expandable component.
        const ExpandedComponent = ({ data }) => <><div className="container">
            <div className="row">
                <table className="w-100 reservation-detail">
                    <tr className="w-100">
                        <td>
                            <p className="table-title">From Date</p>
                            <p className="table-description">{data.from}</p>
                        </td>
                        <td>
                            <p className="table-title">To Date</p>
                            <p className="table-description">{data.to}</p>
                        </td>
                        <td>
                            <p className="table-title">Date of stay</p>
                            <p className="table-description">{data.nbNight}</p>
                        </td>
                        {Array.isArray(data.nbadults) &&
                            data.nbadults.map((nb, index) => (
                                <td>
                                    <p className="table-title">Pax Room {index + 1}</p>
                                    <p className="table-description">{nb.nbAdults} adults</p>
                                    <p className="table-description">{nb.nbChildren[0]} children</p>
                                    <p className="table-description">{nb.nbbaby} baby</p>
                                </td>
                            ))
                        }
                        {!Array.isArray(data.nbadults) &&
                            <td>
                                <p className="table-title">Pax</p>
                                <p className="table-description">{data.nbadults} adults</p>
                                <p className="table-description">{data.nbchildren} children</p>
                                <p className="table-description">{data.nbbaby} baby</p>
                            </td>
                        }
                        <td>
                            <p className="table-title">Total</p>
                            <p className="table-description">{data.finalPrice} {data.Devise}</p>
                        </td>
                    </tr>
                    <tr className="w-100">
                        {!Array.isArray(data.room) &&
                            <td>
                                <p className="table-title">Room Name</p>
                                <p className="table-description">{data.room}</p>
                            </td>
                        }
                        {Array.isArray(data.room) &&
                            <td>
                                {data.room.map((ro, index) => (
                                    <div>
                                        <p className="table-title">Room {index + 1} Name</p>
                                        <p className="table-description">{ro.idRoom['nameRoom']}</p>
                                    </div>
                                ))}
                            </td>
                        }
                        <td>
                            {!Array.isArray(data.board) &&
                                <>
                                    <p className="table-title">Board</p>
                                    <p className="table-description">{data.board}</p>
                                </>
                            }
                            {Array.isArray(data.board) &&
                                data.board.map((bo, index) => (
                                    <>
                                        <p className="table-title">Board Room {index + 1}</p>
                                        <p className="table-description">{bo.board}</p>
                                    </>
                                ))
                            }
                        </td>
                        <td>
                            <p className="table-title">Hotel/City</p>
                            <p className="table-description">{data.hotelname}</p>
                        </td>
                    </tr>
                    <tr className="w-100">
                        <td>
                            <p className="table-title">Cancellation Policy</p>
                            <p className="table-description">{data.cancellationdate} OnWards No Show - {data.fees} % charge will be levied </p>
                        </td>
                    </tr>
                    <tr className="w-100">
                        {!Array.isArray(data.nbadults) &&
                            <td>
                                <p className="table-title">Passengers</p>
                                {data.passengers.map((el => (
                                    <div className="d-flex align-items-center">
                                        <p className="table-description">{el[0]}</p>
                                        <p className="table-description">{el[1]}</p>
                                        {el[2] ? <p className="table-description">{el[2]} (y/o)</p> : ''}
                                    </div>
                                )))}
                            </td>
                        }
                        {Array.isArray(data.nbadults) &&
                            <td>
                                {data.passengers.map((element, index) => (
                                    <>
                                        <p className="table-title">Passengers Room {index + 1}</p>
                                        {element.pax.map((ell, index) => (
                                            <div className="d-flex align-items-center">
                                                <p className="table-description">{ell[0]}</p>
                                                <p className="table-description">{ell[1]}</p>
                                                {ell[2] ? <p className="table-description">{ell[2]} (y/o)</p> : ''}
                                            </div>
                                        ))}
                                    </>
                                ))}
                            </td>
                        }
                    </tr>
                    {(this.props.data.roles.indexOf("ROLE_PARTICULAR") != -1 && data.stripe!=null)  ?
                        <tr className="w-100">
                            <td>
                                <p className="table-title">Payment Type</p>
                                <p className="table-description">Stripe</p>
                            </td>
                            {/*   <td>
                            <p className="table-title">Avg. Price / Pax	</p>
                            <p className="table-description">100 TND</p>
                        </td> */}
                            <td>
                                <p className="table-title">Stripe id :</p>
                                <p className="table-description">{data.stripe} </p>
                            </td>
                        </tr>
                        : ''}
                    {this.props.data.roles.indexOf("ROLE_PARTICULAR") == -1 ?
                        <tr className="w-100">
                            <td>
                                <p className="table-title">Commission in %</p>
                                <p className="table-description">{data.commission} %</p>
                            </td>
                            {/*   <td>
                            <p className="table-title">Avg. Price / Pax	</p>
                            <p className="table-description">100 TND</p>
                        </td> */}
                            <td>
                                <p className="table-title">Total amount</p>
                                <p className="table-description">{data.finalPrice} {data.Devise}</p>
                            </td>
                        </tr>
                        : ''}
                    {data.status == "Confirmed" &&
                        <tr className="w-100">
                            <td colspan="100%">
                                <div className="btns-reservationdetails">
                                    {data.cancellationbool  &&  <button className="btn btn-danger" onClick={() => this.togglecancel(2, data.bookingreference)}>Cancel</button> } 
                                    <a href={`${process.env.REACT_APP_API_KEY_PROD}generer_proforma/` + data.bookingreference}> <button className="btn btn-success">proforma</button></a>
                                    <a href={`${process.env.REACT_APP_API_KEY_PROD}generer_voucher/` + data.bookingreference}><button className="btn btn-success">Voucher</button></a>
                                </div>
                            </td>
                        </tr>
                    }
                </table>
            </div>
        </div></>;
        const customStyles = {
            headCells: {
                style: {
                    background: '#0f2453',
                    color: 'white',
                    fontWeight: '500',
                },
            },
            rows: {
                style: {
                    fontWeight: 'bold',
                    background: '#fafdfd',
                },
            },
        };
        const columns = [
            {
                name: 'Booking Reference',
                selector: row => row.bookingreference,

            },
            {
                name: 'Creation Date',
                selector: row => row.creationdate,
            },
            {
                name: 'cancellation charges until',
                selector: row => row.cancellationcharges,
            },
            {
                name: 'Passenger',
                selector: row => row.passenger,
            },
            {
                name: 'status',
                selector: row => row.status,
            },
        ];
        const { date } = this.state;

        return (
            <div className="Backoffice">
                <CancelModal cancelModal={this.state.modal2} toggle={this.togglecancel} reference={this.state.cancelref} />
                <div className="container">
                    <div className="mr-3 d-flex align-items-center  mb-2"> <i className="far fa-list-alt"></i> <h1 className="mb-0 ml-2 cursor-pointer">Find Booking</h1></div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="reservation-wrapper">
                                <div className="card-bookingreservation cardreservation">
                                    <label>Booking Number</label>
                                    <div className="d-flex">
                                        <input type="text" placeholder="E.g. 12-558872" value={this.state.ref} onChange={(e) => this.setState({ ref: e.target.value })}></input>
                                        {this.state.clickedbutton1 ?
                                            <Button disabled className="btnsearch">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </Button> :
                                            <button className="btnsearch ml-0 mr-0" onClick={() => this.filterBooking(true)}>Search</button>
                                        }
                                    </div>
                                    {/* <p className="mt-5 note-resa">  Please be aware that when searching by ranges of dates the Force Majeure information
                                          will only be displayed for a date range that is less than 3 months old from today.
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="reservation-wrapper-2">
                                <div className="card-bookingreservation">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-5 pl-1 pr-1">
                                                <label>Date</label>
                                                <select type="text" placeholder="E.g. 12-558872" className="w-100" onChange={(e) => this.setState({ type: e.target.value })}>
                                                    <option selected value="1">Booking confirmation</option>
                                                    <option value="2">Check in</option>
                                                </select>
                                            </div>
                                            <div className="col-5 pl-1 pr-1 d-flex flex-column">
                                                <label>From</label>
                                                <Flatpickr
                                                    options={{ mode: "range" }}
                                                    value={this.state.date}
                                                    onChange={this.onChangeDates}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5 pl-1 pr-1">
                                                <label>Reference</label>
                                                <input className="w-100" value={this.state.referencet} type="text" onChange={(e) => this.setState({ referencet: e.target.value })}></input>
                                            </div>
                                            <div className="col-5 pl-1 pr-1">
                                                <label>Booking status</label>
                                                <select type="text" placeholder="E.g. 12-558872" className="w-100" onChange={(e) => this.setState({ status: e.target.value })}>
                                                    <option value="All">All</option>
                                                    <option value="Confirmed">Confirmed</option>
                                                    <option value="Cancelled">Cancelled</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5 pl-1 pr-1">
                                                {/*     <label>Main passenger name or surname</label>
                                                <input className="w-100" value="" type="text"></input> */}
                                            </div>
                                            {/*       <div className="col-5 pl-1 pr-1">
                                                <label>Cancellation fees</label>
                                                <select type="text" placeholder="E.g. 12-558872" className="w-100">
                                                    <option>All</option>
                                                    <option>Free cancellation fees</option>
                                                </select>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-5 pl-1 pr-1">
                                            </div>
                                            <div className="col-lg-5 pl-1 pr-1 d-flex justify-content-end">
                                                {this.state.clickedbutton2 ?
                                                    <Button disabled className="btnsearch">
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </Button> :
                                                    <button className="btnsearch ml-0 mr-0" onClick={() => this.filterBooking(false)}>Search</button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-5 mb-5 datatable-ff">
                        <div className="col-12">
                            <DataTable
                                columns={columns}
                                data={data}
                                expandableRows
                                expandableRowsComponent={ExpandedComponent}
                                pagination
                                customStyles={customStyles}
                            />
                        </div>
                    </div>

                </div>

            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Backoffice);