import "../../../App.css";
import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import jwt_decode from "jwt-decode";
import { Spinner, Button } from 'react-bootstrap';
import { LOGEDUSER } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";

const mapDispatchToProps = {
    LOGEDUSER,
};

function mapStateToProps(state) {
    return {
        data: state.userReducer
    }
}

class Forgetpassword extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({
            messages: {
                firstname: 'Some message',
            },
        });
        this.state = {
            email: '',
            errorlogin: '',
            clickedbutton: false,
            message:'',
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    submitForm = (e) => {
        e.preventDefault();
        var self = this;
        if (this.validator.allValid()) {

            this.setState({
                clickedbutton: true,
            })

            fetch(`${process.env.REACT_APP_API_KEY_PROD}api/ForgetPassword/${this.state.email}/${this.props.code}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(function (response) {
                    if (!response.ok) {
                        self.setState({ errorlogin: 'Email Not Found', clickedbutton: false })
                        throw new Error("HTTP status " + response.status);
                    }
                    return response.json();
                })
                .then(
                    (result) => {
                         self.setState({
                            message:'Email has been sent , Please check your mail box',
                            clickedbutton: false,
                         })
                    },
                    (error) => {
                        self.setState({ message: 'Email Not Found', clickedbutton: false })
                    }
                )

        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    render() {
        if (this.props.data.loged) return (
            <Redirect to={`/?hotelid=${this.props.code}`} />
        )
        return (
            <div className="LoginApp">
                <div className="open">
                    <div className="container login-row">
                        <div className="row w-100">
                            <div className="col-12 col-md-6 col-lg-6 border-l">
                                <div className="form form--login">
                                    <form className="js-form-gms-login">
                                        <div className="form__head">
                                            <div className="h5 form__title">Forget Password</div>
                                        </div>
                                        <div className="form__body">
                                            <p className="form__meta">* Required Fields</p>
                                            <div className="form__row">
                                                <label htmlFor="field-login-email" className="form__label">
                                                    Email Address *
                                                </label>
                                                <div className="form__controls">
                                                    <input
                                                        type="email"
                                                        className="field"
                                                        placeholder="Email Address *"
                                                        required=""
                                                        value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}
                                                    ></input>
                                                    {this.validator.message('email', this.state.email, 'required|email')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__actions">
                                            {this.state.clickedbutton ?
                                                <Button disabled className="form__btn ">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button> :
                                                <button
                                                    type="submit"
                                                    className="form__btn "
                                                    value=""
                                                    onClick={(e) => this.submitForm(e)}
                                                >
                                                    SEND
                                                </button>
                                            }
                                            <div className="srv-validation-message text-center">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="section__inner form--login">
                                    <div className="section__body">
                                        <div className="section__entry">
                                            <div
                                                className="h5 section__subtitle"
                                                id="title-id-23e23b47a1ccdef76d9d0c5705f2ed7f"
                                            >
                                                Become a  Journeys member
                                            </div>

                                            <p>
                                                Inspiring You To Go Further. That’s our commitment. And
                                                that’s what  Journeys, our exclusive recognition
                                                programme, is designed to do.
                                            </p>
                                        </div>
                                        <div className="loginicons">
                                            <div className="d-flex flex-column align-items-center">
                                                <i className="fas fa-piggy-bank"></i>
                                                <p>Best Rates Guarantee</p>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                                <i className="far fa-clock"></i>
                                                <p>Early Check-in & Late Check-out</p>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                                <i className="fas fa-wifi"></i>
                                                <p>Free Premium Wi-Fi</p>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                                <i className="fas fa-medal"></i>
                                                <p>TripAdvisor Awards</p>
                                            </div>
                                        </div>
                                        <NavLink to={`/signup?hotelid=${this.props.code}`}>
                                            <input
                                                type="submit"
                                                value="Become Partner"
                                                className="form__btn"
                                            ></input>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgetpassword)