import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Spinner, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import jwt_decode from "jwt-decode";


function mapStateToProps(state) {
    return {
        user: state.userReducer
    }
}


class ModalMarkUp extends Component {
    constructor(props) {
        super();
        /******* INITIAL COMPONENT MAIN STATES ******/
        this.state = {
            markup: 0,
            clickedbutton: false,
            error: "",
            /******* INITIAL COMPONENT MAIN STATES ******/
        }
    }
    componentDidMount() {
        var token = localStorage.getItem('token');
     
        if (token) {
            var decoded = jwt_decode(token);
       
            this.setState({
                markup:decoded.markupagence,
            })
        }
    }
    UpdateMarkup = () => {
        this.setState({
            clickedbutton: true,
        })
        fetch(`${process.env.REACT_APP_API_KEY_PROD}api/UpdateAgenceMarkup`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "token": localStorage.getItem("token"), "markup": this.state.markup, "username": this.props.user.username, "email": this.props.user.email
            })
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                    localStorage.setItem("token", result.token)
                    this.setState({
                        clickedbutton: false,
                        error: "Updated successfully"
                    })
                },
                (error) => {
                    this.setState({
                        clickedbutton: false,
                        error: "an error accured"
                    })
                }
            ) .then(function (response) {
              window.location.reload();
            })
    }
    render() {
        return (
            <MDBContainer >
                <MDBModal isOpen={this.props.ModalMarkUp} toggle={this.props.toggle(14)} centered className='modal-markup'>
                    <MDBModalHeader toggle={this.props.toggle(14)}>Add Markup</MDBModalHeader>
                    <MDBModalBody>
                        <p>With this module you can calculate the final price of your booking adding a Markup.
                            Prices will only be modified in the search results and the email factsheet sending to your clients
                            this change does not affect the price displayed in the shopping cart.</p>
                        <div className="form__row w-100">
                            <label for="field-login-email" className="form__label mt-0">
                                Percentage %</label>
                            <div className="form__controls">
                                <input className="w-100" type="number" value={this.state.markup} placeholder="" onChange={(e) => this.setState({ markup: e.target.value })}></input>
                            </div>
                            <div className='d-flex'>
                                {this.state.clickedbutton ?
                                    <Button variant="primary" disabled className="btn-action-markup">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                    :
                                    <button className="btn-action-markup" onClick={this.UpdateMarkup}>Apply Changes</button>
                                }
                                {/* <button className="btn-action-markup ml-2">Delete Markup</button> */}
                            </div>
                            <p style={{ color: "green" }}>{this.state.error}</p>
                        </div>
                    </MDBModalBody>
                </MDBModal>
            </MDBContainer>
        );
    }
}

export default connect(mapStateToProps, null)(ModalMarkUp)