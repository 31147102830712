import React, { Component, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { DEVISE } from '../../redux/actions';
import ModalMarkUp from '../Components/backoffice/MarkUpModal';

function Header(props) {
  const { useState, useEffect } = React;
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!isOpen);
  const [modal14, setmodal14] = useState(false);
  const Devise = useSelector((state) => state.DeviseReducer);
  const dispatch = useDispatch();
  const SelectCurrency = useRef(null);
  
  const ChangeCurrency = async (e) => {
    if (typeof props.currency[e.target.value] === 'undefined') {
      window.location.reload();
    }
    else {
      await dispatch(DEVISE(props.currency[e.target.value]));
      window.location.reload();
    }
  }

  const refreshExchange = (e) => {
    if (typeof props.currency[e.target.value] === 'undefined') {
    }
    else {
      dispatch(DEVISE(props.currency[e.target.value]));
    }
  }

  useEffect(() => {

  if (SelectCurrency.current != null) {
      SelectCurrency.current.click();
    }
  }, [SelectCurrency.current]);

  const toggle = nr => () => {
    setmodal14(!modal14);
  }

  return (
    <div>
      {props.user.loged ?
        <div className="main-header container">
          <div className="d-flex">
            <div className="mr-3 d-flex align-items-center"> <i className="fas fa-user-cog"></i> <NavLink exact to={`/Backoffice/EditUser?hotelid=${props.code}`} className="Signin"> <p className="mb-0 ml-2 cursor-pointer">User Admin</p></NavLink></div>
            <div className="mr-3 d-flex align-items-center"> <i className="far fa-list-alt"></i> <NavLink exact to={`/Backoffice?hotelid=${props.code}`} className="Signin"> <p className="mb-0 ml-2 cursor-pointer">Find Booking</p></NavLink></div>
            {props.user.roles.indexOf("ROLE_PARTICULAR") == -1 && <div className="mr-3 d-flex align-items-center" > <i className="fas fa-percentage"></i> <p className="mb-0 ml-2 cursor-pointer" onClick={toggle(14)}>Markup</p></div>}
          </div>
          <div className="d-flex">
            {props.user.roles.indexOf("ROLE_PARTICULAR") == -1 && <div className="mr-3 d-flex align-items-center credit">Your Credit &nbsp; <strong> {props.user.deposit} {Devise.devise}</strong></div>}
            <select className="mr-2"><option selected>ENG</option><option>FR</option></select>
            {props.user.roles.indexOf("ROLE_PARTICULAR") != -1 && <select className="mr-2" onChange={ChangeCurrency} onClick={refreshExchange} ref={SelectCurrency}> {props.currency.map((b, i) => ((Devise.devise).toString() == (b.split('_')[0]).toString() ? <option value={i} selected>{b.split('_')[0]}</option> : <option value={i}>{b.split('_')[0]}</option>))}</select>}
            {props.user.roles.indexOf("ROLE_PARTICULAR") == -1 && <select className="mr-2" onClick={refreshExchange} ref={SelectCurrency}> {props.currency.map((b, i) => ((Devise.devise).toString() == (b.split('_')[0]).toString() && <option value={i} selected>{b.split('_')[0]}</option>))}</select>}
            <p className="mb-0 mr-2">Welcome {props.user.username}</p>
            <div className="mr-2 d-flex align-items-center"> <i className="fas fa-sign-out-alt"></i> <p className="mb-0 ml-2 cursor-pointer" onClick={props.logout}>Logout</p></div>
          </div>
        </div>
        :
        <div className="main-header container">
          <div>
            <div className="mr-2 d-flex align-items-center">
              <i className="fas fa-map-marker" aria-hidden="true">
              </i>
              <p className="mb-0 ml-2">{props.address}</p>
            </div>
          </div>
          <div className="d-flex">
            <select className="mr-2"><option selected>ENG</option><option>FR</option></select>
            <select className="mr-2" ref={SelectCurrency} onChange={ChangeCurrency} onClick={refreshExchange}> {props.currency.map((b, i) => ((Devise.devise).toString() == (b.split('_')[0]).toString() ? <option value={i} selected>{b.split('_')[0]}</option> : <option value={i}>{b.split('_')[0]}</option>))}</select>
            <div className="mr-2 d-flex align-items-center"> <i className="fas fa-user"></i>
              <NavLink exact to={`/login?hotelid=${props.code}`} className="Signin">
                <p className="mb-0 ml-2 cursor-pointer" >SIGN IN</p>
              </NavLink>
            </div>
          </div>
        </div>
      }
      <div className={click ? "main-container " : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="nav-container container p-0">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <div className="d-flex justify-content-between menu-items">
              <li className="nav-item">
                <div className="nav-links"
                >
                  <div className="indicator-arrow"></div>
                  GUESTS
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-links"
                >
                  <div className="indicator-arrow"></div>
                  ROOMS
                </div>
              </li>
            </div>
            <div>
              <NavLink exact to={`/?hotelid=${props.code}`} className="nav-logo">
                <img
                  className="logo"
                  src={process.env.REACT_APP_API_KEY_PROD + props.logo}
                ></img>
              </NavLink>
            </div>
            <div className="d-flex justify-content-between menu-items">
              <li className="nav-item ">
                <div className="nav-links ml-2">
                  <div className="indicator-arrow"></div>
                  TOTAL PRICE
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-links">
                  <div className="indicator-arrow"></div>
                  CONFIRMATION
                </div>
              </li>
            </div>
          </ul>
        </div>
      </nav>
      {props.user.roles.indexOf("ROLE_PARTICULAR") == -1 && <ModalMarkUp ModalMarkUp={modal14} toggle={toggle}/> }
    </div>
  );
}

export default Header;
