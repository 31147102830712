import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Spinner, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import jwt_decode from "jwt-decode";
import { LOGEDUSER } from '../../../redux/actions';

function mapStateToProps(state) {
    return {
        user: state.userReducer
    }
}

const mapDispatchToProps = {
    LOGEDUSER,
  };
class cancelModal extends Component {
    constructor(props) {
        super();
        /******* INITIAL COMPONENT MAIN STATES ******/
        this.state = {
            markup: 0,
            clickedbutton: false,
            error: "",
            /******* INITIAL COMPONENT MAIN STATES ******/
        }
    }

    UpdateMarkup = () => {
        this.setState({
            clickedbutton: true,
        })
        fetch(`${process.env.REACT_APP_API_KEY_PROD}api/CancelReservation`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "token": localStorage.getItem("token"), "reference": this.props.reference, "username": this.props.user.username, "email": this.props.user.email
            })
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                    if (result.token) {
                        localStorage.setItem("token", result.token);
                    }
                    window.location.reload();
                },
                (error) => {
                    this.setState({
                        clickedbutton: false,
                        error: "an error accured"
                    })
                }
            )
    }
    render() {
        return (
            <MDBContainer >
                <MDBModal isOpen={this.props.cancelModal} toggle={() => this.props.toggle(2)} centered className='modal-markup'>
                    <MDBModalHeader toggle={() => this.props.toggle(2)}>Are you sure to cancel this Reservation ?</MDBModalHeader>
                    <MDBModalBody>
                        <div className="form__row w-100">
                            <div className='d-flex'>
                                {this.state.clickedbutton ?
                                    <Button variant="danger" disabled className="btn btn-danger w-100">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                    :
                                    <button className="btn btn-danger w-100" onClick={this.UpdateMarkup}>Cancel Reservation</button>
                                }
                                {/* <button className="btn-action-markup ml-2">Delete Markup</button> */}
                            </div>
                            <p>{this.state.error}</p>
                        </div>
                    </MDBModalBody>
                </MDBModal>
            </MDBContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(cancelModal)