import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import React, { useState } from "react";

export default function IntPhone(props) {

    return (
        <>

            <PhoneInput
                international
                defaultCountry="TN"
                value={props.phone}
                onChange={(value)=>props.phoneFn(value)}
            />

            {props.phone ? (isValidPhoneNumber(props.phone) ? undefined : <div class="srv-validation-message">Invalid phone number.</div>) : <div class="srv-validation-message">{props.phonemsgerror}</div>}

        </>

    )

}