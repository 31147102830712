import "../../App.css";
import React, { Component } from "react";
import "../../flatpicker.css";
import {
  ROOMSBOOKED,
  LOADER,
  NAME,
  EMAIL,
  PHONE,
  SPECIALREQUEST,
  PAX,
  CREDIT,
} from "../../redux/actions";
import { connect } from "react-redux";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import ReactFlagsSelect from "react-flags-select";
import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { loadStripe } from "@stripe/stripe-js";
import { Spinner, Button } from "react-bootstrap";
import Stripform from "./stripe";

function mapStateToProps(state) {
  return {
    data: state.reducer,
    user: state.userReducer,
    Devise: state.DeviseReducer,
  };
}

const mapDispatchToProps = {
  ROOMSBOOKED,
  LOADER,
  NAME,
  EMAIL,
  PHONE,
  SPECIALREQUEST,
  PAX,
  CREDIT,
};

class Price extends Component {
  constructor(props) {
    super();
    this.picker2 = React.createRef();
    this.validator = new SimpleReactValidator({
      messages: {
        firstname: "Some message",
      },
    });
    this.state = {
      date: new Date(),
      firstname: "",
      lastname: "",
      email: "",
      telephone: "",
      nationality: "",
      address: "",
      specialrequest: "",
      selected: "TN",
      redirect: false,
      pax: [],
      clickedbutton: false,
      errormsg: "",

    };
  }

  componentDidMount() {
    var joined = [];
    this.props.data.roomsbooked.map((e, i) => {
      var dt = [];
      [...Array(e.guests[0])].map((el, ig) => {
        dt.push(["", ""]);
      });
      [...Array(e.guests[1][0])].map((ell, igg) => {
        dt.push(["", "", e.guests[1][igg + 1]]);
      });
      joined[i] = dt;
    });
    this.setState({ pax: joined });
    var token = localStorage.getItem("token");
    if (token) {
      var decoded = jwt_decode(token);
      if (decoded.exp * 1000 < Date.now()) {
        localStorage.removeItem("token");
      } else {
        this.setState({
          firstname: decoded.name,
          email: decoded.email,
          telephone: decoded.phone,
          selected: decoded.Country,
        });
      }
    } else {
      window.location.href = `/?hotelid=${this.props.code}`;
    }
    window.scrollTo(0, 0);
  }

  OpenDatePicker = () => {
    this.picker2.current.flatpickr.clear();
    this.picker2.current.flatpickr.open();
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        clickedbutton: true,
      });
      if (this.props.user.roles.indexOf("ROLE_PARTICULAR") !== -1) {
        this.StripeCheckout();
      } else {
        this.Checkout();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  checkForm = (e) => {
   
    if (this.validator.allValid()) {
      this.props.SPECIALREQUEST(this.state.specialrequest);
      this.props.PAX(this.state.pax);
      return true;
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
  };

  paxUpdateValue = (e, a, b, c) => {
    let items = [...this.state.pax];
    let item = { ...items[a][b][c] };
    item = e.target.value;
    items[a][b][c] = item;
    this.setState({ items });
  };

  StripeCheckout = async () => {
    this.props.SPECIALREQUEST(this.state.specialrequest);
    this.props.PAX(this.state.pax);
    const stripePromise = loadStripe(
      "pk_test_51MGfytDcnsaCP3IqkDoTK2GF76Ysy7Zt8alFtESZ7YB5kJ9QB23DtVftL4sKpXUm53BzThB3gSupxALcogjkU7fk00TsZPQu00"
    );
    const stripe = await stripePromise;
    fetch(
      `${process.env.REACT_APP_API_KEY_PROD}create-checkout-session/${this.props.data.totalbooking}/${this.props.code}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rooms: this.props.data.roomsbooked }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (session) {
        return stripe.redirectToCheckout({ sessionId: session.id });
      })
      .then(function (result) {
        if (result.error) {
        }
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  setMsgError=(msg)=>{
     this.setState({
      errormsg: msg,
          });
  }
  Checkout = () => {
    this.props.SPECIALREQUEST(this.state.specialrequest);
    fetch(
      `${process.env.REACT_APP_API_KEY_PROD}api/hotelbooking/${this.props.code}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          arriving: moment(this.props.data.arriving).format("YYYY/MM/DD"),
          departing: moment(this.props.data.departing).format("YYYY/MM/DD"),
          rooms: this.props.data.roomsbooked,
          devise: this.props.Devise.devise,
          firstlastname: this.props.user.firstlastname,
          email: this.props.user.email,
          telephone: this.props.user.phone,
          nationality: this.props.user.country,
          address: this.props.user.address,
          finalprice: this.props.data.totalbooking,
          nights: this.props.data.nights,
          specialrequest: this.state.specialrequest,
          pax: this.state.pax,
          username: this.props.user.username,
        }),
      }
    )
      .then(function (response) {
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then(
        (result) => {
          this.props.CREDIT(result.deposit);
          localStorage.setItem("token", result.token);
          this.setState({
            redirect: true,
          });
        },
        (error) => {
          this.setState({
            errormsg: "insufficient balance",
            clickedbutton: false,
          });
        }
      );
  };
  // {props .user.roles.indexOf("ROLE_PARTICULAR") == -1 &&

  render() {
    console.log(this.state.pax);
    if (this.state.redirect) {
      this.props.LOADER(false);
      return <Redirect to={`/backoffice/?hotelid=${this.props.code}`} />;
    }

    return (
      <div className="App bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 sidebar_wrapper">
              <div className="sidebarcontainer">
                <aside className="sidebar sidebar-guests">
                  <aside
                    id="hotelier-widget-booking-2"
                    className="widget widget--hotelier widget-booking"
                  >
                    <h5 className="widget-title">My Stay</h5>
                    <div className="d-flex widget-stay justify-content-between">
                      <div>
                        <strong>Check In</strong>
                        <p>After 12:00 AM</p>
                        <div className="d-flex">
                          <p>
                            {moment(this.props.data.arriving).format("ddd")}
                          </p>
                          <p className="ml-2">
                            {moment(this.props.data.arriving).format("D")}/
                            {moment(this.props.data.arriving).format("MM")}/
                            {moment(this.props.data.arriving).format("YYYY")}
                          </p>
                        </div>
                      </div>
                      <div className="ml-5">
                        <strong>Check Out</strong>
                        <p>After 12:00 PM</p>
                        <div className="d-flex">
                          <p>
                            {moment(this.props.data.departing).format("ddd")}
                          </p>
                          <p className="ml-2">
                            {moment(this.props.data.departing).format("D")}/
                            {moment(this.props.data.departing).format("MM")}/
                            {moment(this.props.data.departing).format("YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </aside>
                  <aside
                    id="hotelier-widget-rooms-filter-2"
                    className="widget widget--hotelier widget-rooms-filter"
                  >
                    <div className="widget-rooms-filter__wrapper">
                      {this.props.data.roomsbooked.length >= 1 && (
                        <div className="yourreservation">
                          {this.props.data.roomsbooked.map((b, i) => (
                            <>
                              <div className="d-flex justify-content-between">
                                <p className="yourreservationdet reservation-room-nbr">
                                  Room {i + 1}
                                </p>
                                <p className="yourreservationdet reservation-room-nbr priceslist">
                                  {b.boards[0].apiprice *
                                    this.props.Devise.exchange +
                                    this.props.Devise.devise}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <u>
                                  <p className="yourreservationdet">
                                    {b.nameRoom}
                                  </p>
                                </u>
                              </div>
                              <div className="d-flex align-items-center">
                                <i className="fas fa-mug-hot"></i>
                                <p className="yourreservationdet ml-2 reservation-room-nbr">
                                  {b.boards[0].description}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between taxandfees">
                                <p className="yourreservationdet">
                                  Taxes And Fees (7%)
                                </p>
                                <p className="yourreservationdet reservation-room-nbr fees">
                                  {b.boards[0].tax * this.props.Devise.exchange}{" "}
                                  {this.props.Devise.devise}
                                </p>
                              </div>
                            </>
                          ))}
                          <div className="d-flex justify-content-between">
                            <p className="yourreservationdet reservation-room-total">
                              Total
                            </p>
                            <p className="yourreservationdet reservation-room-total reservation-room-nbr priceslist">
                              {this.props.data.totalbooking *
                                this.props.Devise.exchange}{" "}
                              {this.props.Devise.devise}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </aside>
                </aside>
                <aside className="sidebar sidebar-guests mt-2">
                  <aside
                    id="hotelier-widget-booking-2"
                    className="widget widget--hotelier widget-booking"
                  >
                    <h5 className="widget-title">Cancellation Pollicy</h5>
                    <div>
                      <p className="cancellation-policytxt">
                        Cancellation and modification free of charge up to 24
                        hours before the date of arrival.
                      </p>
                    </div>
                  </aside>
                </aside>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">
              <div className="d-flex align-items-center">
                {/* <i className="fas fa-arrow-left"></i> */}
                <h3 className="sectionguest-header__title">Guest details</h3>
              </div>
              <hr></hr>
              <form>
                <h3 className="sectionguest-title__title">Contact Info</h3>
                <div className="row mt-2">
                  <div className="col-lg-12 col-md-12 d-flex flex-column">
                    <div className="card-form">
                      <label className="form-row__label form-input-label">
                        First-last name{" "}
                      </label>
                      <input
                        readOnly
                        type="text"
                        className="input-text form-input-guest"
                        required=""
                        value={this.state.firstname}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-6 col-md-6 d-flex flex-column">
                    <div className="card-form">
                      <label className="form-row__label form-input-label">
                        Phone{" "}
                      </label>
                      <input
                        readOnly
                        type="text"
                        className="input-text form-input-guest"
                        required=""
                        value={this.state.telephone}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 d-flex flex-column">
                    <div className="card-form">
                      <label className="form-row__label form-input-label">
                        Email address{" "}
                      </label>
                      <input
                        readOnly
                        type="text"
                        className="input-text form-input-guest"
                        value={this.state.email}
                      ></input>
                    </div>
                    <div className="app-field-message">
                      <span>
                        * This is the email we will send your confirmation to.
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label className="form-row__label form-input-label">
                      Nationality{" "}
                    </label>
                    <ReactFlagsSelect
                      selected={this.state.selected}
                      onSelect={(code) => this.setState({ selected: code })}
                      searchable
                      selectButtonClassName="menu-flags-guest-btn"
                      className="menu-flags-guest"
                    />
                    {this.validator.message(
                      "selected",
                      this.state.selected,
                      "required"
                    )}
                  </div>
                </div>
                <hr></hr>
                {this.state.pax.length > 0 && (
                  <>
                    <h3 className="sectionguest-title__title">
                      Passangers List
                    </h3>
                    {this.props.data.roomsbooked.map((e, i) => (
                      <>
                        <h4 className="sectionguest-title__title mt-1">
                          Room {i + 1}
                        </h4>
                        {[...Array(e.guests[0])].map((e, ig) => (
                          <>
                            <h4 className="sectionguest-title mt-1">
                              Adult {ig + 1}
                            </h4>
                            <div className="row mt-3">
                              <div className="col-lg-6 col-md-6 d-flex flex-column">
                                <div className="card-form">
                                  <label className="form-row__label form-input-label">
                                    First name{" "}
                                    <abbr className="required" title="required">
                                      *
                                    </abbr>
                                  </label>
                                  <input
                                    type="text"
                                    className="input-text form-input-guest"
                                    onChange={(event) =>
                                      this.paxUpdateValue(event, i, ig, 0)
                                    }
                                    value={this.state.pax[i][ig][0]}
                                  ></input>
                                </div>
                                {this.validator.message(
                                  "First name",
                                  this.state.pax[i][ig][0],
                                  "required"
                                )}
                              </div>
                              <div className="col-lg-6 col-md-6 d-flex flex-column">
                                <div className="card-form">
                                  <label className="form-row__label form-input-label">
                                    Last name{" "}
                                    <abbr className="required" title="required">
                                      *
                                    </abbr>
                                  </label>
                                  <input
                                    type="text"
                                    className="input-text form-input-guest"
                                    onChange={(event) =>
                                      this.paxUpdateValue(event, i, ig, 1)
                                    }
                                    value={this.state.pax[i][ig][1]}
                                  ></input>
                                </div>
                                {this.validator.message(
                                  "Last name",
                                  this.state.pax[i][ig][1],
                                  "required"
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                        {[...Array(e.guests[1][0])].map((eg, igc) => (
                          <>
                            <h4 className="sectionguest-title mt-1">
                              Child {igc + 1}
                            </h4>
                            <div className="row mt-3">
                              <div className="col-lg-5 col-md-5 d-flex flex-column">
                                <div className="card-form">
                                  <label className="form-row__label form-input-label">
                                    First name{" "}
                                    <abbr className="required" title="required">
                                      *
                                    </abbr>
                                  </label>
                                  <input
                                    type="text"
                                    className="input-text form-input-guest"
                                    required=""
                                    onChange={(event) =>
                                      this.paxUpdateValue(
                                        event,
                                        i,
                                        igc + e.guests[0],
                                        0
                                      )
                                    }
                                    value={
                                      this.state.pax[i][igc + e.guests[0]][0]
                                    }
                                  ></input>
                                </div>
                                {this.validator.message(
                                  "Child First name",
                                  this.state.pax[i][igc + e.guests[0]][0],
                                  "required"
                                )}
                              </div>
                              <div className="col-lg-5 col-md-5 d-flex flex-column">
                                <div className="card-form">
                                  <label className="form-row__label form-input-label">
                                    Last name{" "}
                                    <abbr className="required" title="required">
                                      *
                                    </abbr>
                                  </label>
                                  <input
                                    type="text"
                                    className="input-text form-input-guest"
                                    onChange={(event) =>
                                      this.paxUpdateValue(
                                        event,
                                        i,
                                        igc + e.guests[0],
                                        1
                                      )
                                    }
                                    value={
                                      this.state.pax[i][igc + e.guests[0]][1]
                                    }
                                  ></input>
                                </div>
                                {this.validator.message(
                                  "Child Last name",
                                  this.state.pax[i][igc + e.guests[0]][1],
                                  "required"
                                )}
                              </div>
                              <div className="col-lg-2 col-md-2 d-flex flex-column">
                                <div className="card-form">
                                  <label className="form-row__label form-input-label">
                                    Age{" "}
                                  </label>
                                  <input
                                    readOnly
                                    type="text"
                                    className="input-text form-input-guest"
                                    value={e.guests[1][igc + 1]}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    ))}
                    <hr></hr>
                    <h3 className="sectionguest-title__title">
                      Special request
                    </h3>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 d-flex flex-column">
                        <textarea
                          type="text"
                          className="input-text special_request"
                          value={this.state.specialrequest}
                          onChange={(e) =>
                            this.setState({ specialrequest: e.target.value })
                          }
                        ></textarea>
                        <div className="app-field-message">
                          <span>
                            Special requests cannot be guaranteed but we will do
                            our best to meet your needs.
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {this.props.user.roles.indexOf("ROLE_PARTICULAR") !== -1 && (
                  <>
                    <hr></hr>
                    <h3 className="sectionguest-title__title">
                      Select payment method
                    </h3>
                    <div className="app-field-message">
                      <span>
                        By proceeding with the reservation I agree to the{" "}
                        <b className="cursor-pointer">
                          {" "}
                          online booking rules,processing of personal data and
                          to the privacy policy
                        </b>
                      </span>
                    </div>
                
                    {this.props.user.roles.indexOf("ROLE_PARTICULAR") !==
                      -1 && (
                      <>
                        <div class="card-form card-form-secure mt-3">
                          <h3 className="sectionguest-title__title mb-3 mt-1">
                            Payment Information
                          </h3>
                          <div className="d-flex">
                          <h5>Bank Card (Visa, MasterCard) </h5>
                          <img
                            src={require("../../img/credit-cards/Visa.png")}
                            width="40px"
                            className="ml-1"
                          ></img>
                          <img
                            src={require("../../img/credit-cards/MasterCard.png")}
                            width="40px"
                            className="ml-1"
                          ></img>
                        </div>
                          <Stripform
                            totalbooking={this.props.data.totalbooking}
                            code={this.props.code}
                            roomsbooked={this.props.data.roomsbooked}
                            validator={this.checkForm}
                            data={this.props.data}
                            user={this.props.user}
                            Devise={this.props.Devise}
                            setMsgError={this.setMsgError}
                            SPECIALREQUEST={this.props.SPECIALREQUEST}
                            PAX={this.state.pax}
                          />
                          <div className="srv-validation-message text-center">
                            {this.state.errormsg}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="card-form card-form-secure mt-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5>Safety guarantee</h5>
                        </div>
                        <div className="payment-cards">
                          <img
                            src={require("../../img/credit-cards/secure1.png")}
                            width="40px"
                            className="ml-1"
                          ></img>
                          <img
                            src={require("../../img/credit-cards/secure2.png")}
                            width="40px"
                            className="ml-1"
                          ></img>
                        </div>
                      </div>
                      <p>
                        Confidentiality of all personal data and bank card
                        information is fully protected by our internationally
                        certified processing system. Under no circumstances can
                        any party access this protected data via the booking
                        system or otherwise. Confidential data is handled in
                        accordance with the information protection standards
                        developed by Visa and MasterCard — PCI DSS. This data
                        transfer technology guarantees safe storage by following
                        coding protocols and standards provided by 3-D Secure.
                        Money is always refunded to the card on which a payment
                        was made
                      </p>
                    </div>
                  </>
                )}

                {this.props.user.roles.indexOf("ROLE_AGENCY") !== -1 && (
                  <div className="d-flex justify-content-end">
                    <div className="btn-primary-container calendar">
                      {this.state.clickedbutton ? (
                        <Button
                          variant="primary"
                          disabled
                          className="style-1 button-stepper-availibity"
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </Button>
                      ) : (
                        <input
                          type="button"
                          className="btn-primary style-1 button-stepper-availibity"
                          onClick={this.submitForm}
                          value="BOOK"
                        ></input>
                      )}
                      <div className="srv-validation-message text-center">
                        {this.state.errormsg}
                      </div>
                    </div>
                  </div>
                )}

                {this.props.user.roles.indexOf("ROLE_AGENT") !== -1 && (
                  <div className="d-flex justify-content-end">
                    <div className="btn-primary-container calendar">
                      {this.state.clickedbutton ? (
                        <Button
                          variant="primary"
                          disabled
                          className="style-1 button-stepper-availibity"
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </Button>
                      ) : (
                        <input
                          type="button"
                          className="btn-primary style-1 button-stepper-availibity"
                          onClick={this.submitForm}
                          value="BOOK NOW"
                        ></input>
                      )}
                      <div className="srv-validation-message text-center">
                        {this.state.errormsg}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Price);
