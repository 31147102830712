import React, { Component } from "react";
import { Modal, Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import moment from "moment";

function MyVerticallyCenteredModal(props) {
    return (
        <>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <i className="fa fa-times" onClick={props.onHide}></i>
                <div className="d-flex justify-content-around modal-rooms">
                    <Modalbody rooms={props.rooms} checkin={props.checkin} checkout={props.checkout} guests={props.guests} selectedroom={props.selectedroom} fetchData={props.fetchData} hide={props.onHide} />
                </div>
            </Modal>
        </>
    );
}

function Modalbody(props) {
    var content = [];
    var rooms = "";
    for (let i = 0; i < props.rooms; i++) {
        rooms = (<Modal.Body>
            <aside className="sidebar-rooms-modal">
                {i == (props.selectedroom) && <h5 className="widget-title current-room">current room</h5>}
                <aside id="hotelier-widget-booking-2" className="widget widget--hotelier widget-booking">
                    <h5 className="widget-title">Room {i + 1} of {props.rooms}</h5>
                    <div className="d-flex justify-content-between">
                        <div>
                            <strong>Check In</strong>
                            <div className="d-flex">
                                <p>{moment(props.checkin).format('ddd')}</p>
                                <p className="ml-2">{moment(props.checkin).format('D')}/{moment(props.checkin).format('MM')}/{moment(props.checkin).format('YYYY')}</p>
                            </div>
                        </div>
                        <div className="ml-5">
                            <strong>Check Out</strong>
                            <div className="d-flex">
                                <p>{moment(props.checkout).format('ddd')}</p>
                                <p className="ml-2">{moment(props.checkout).format('D')}/{moment(props.checkout).format('MM')}/{moment(props.checkout).format('YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    <p>
                        {props.guests[i][0] == 1 ? props.guests[i][0] + " Adult" :
                            props.guests[i][0] + " Adults"} <br />
                        {props.guests[i][1][0] == 1 ? props.guests[i][1][0] + " Child" :
                            props.guests[i][1][0] + " Children"}<br />
                        {props.guests[i][2] == 1 ? props.guests[i][2] + " Baby" :
                            props.guests[i][2] + " Babies"}
                    </p>
                    <div className="d-flex align-items-center cursor-pointer" onClick={i == (props.selectedroom) ? props.hide :
                            () => [props.fetchData(i), props.hide()]}><i className="fas fa-pen-square"></i> <h5 className="title-edit ml-2">Edit.</h5></div>
                </aside>
            </aside>
        </Modal.Body>)
        content.push(rooms);
    }
    return content;
}

export default function Modalrooms(props) {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div>
            {props.rooms > 1 && (<div className="d-flex justify-content-between"><p className="mb-0">Room {props.selectedroom + 1} of {props.rooms} </p><a><p className="widget-title managerooms mb-0 mt-0" onClick={() => setModalShow(true)}>  Manage Rooms</p></a></div>)}
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                total={props.total}
                rooms={props.rooms}
                checkin={props.checkin}
                checkout={props.checkout}
                guests={props.guests}
                selectedroom={props.selectedroom}
                fetchData={props.fetchData}
            />
        </div>
    );
}