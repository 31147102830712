import React, { Component } from "react";

function Footer(props) {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <p>{props.name}</p>
          </div>
          <div className="col-6 d-flex justify-content-center">
            {/* <u><p >Modify / Cancel reservation</p></u> */}
            <a href={`/?hotelid=${props.code}`} className="retry"><p className="ml-2"><i className="fab fa-rev" ></i><span className="ml-2">Retry</span></p></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
