import React from "react";
import ReactDOM from "react-dom";
import App from "./routes/index";
import reportWebVitals from "./reportWebVitals";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { Router } from "react-router-dom";
import history from "./history";
import configureStore from './redux/store';
import { Provider } from 'react-redux';
import "./index.css";
import { PersistGate } from 'redux-persist/integration/react';

let { store, persistor } = configureStore()


ReactDOM.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById("root")
);

reportWebVitals();
