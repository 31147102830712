import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Home from "../Views/pages";
import Rooms from "../Views/pages/rooms";
import Header from "../Views/pages/header";
import Footer from "../Views/pages/footer";
import Price from "../Views/pages/price";
import Confirmation from "../Views/pages/confirmation";
import Loader from "../Views/loader"
import { connect } from 'react-redux';
import { LOADER, LOGEDUSER,DEVISE } from '../redux/actions';
import NotFound from '../Views/pages/404';
import LoginPage from '../Views/pages/login/login';
import Signup from '../Views/pages/signup/signup';
import Signupsuccess from '../Views/pages/account/signup_success';
import Forgetpassword from '../Views/pages/account/forgetpassword';
import Resetpassword from '../Views/pages/account/resetpassword';
import SignupsuccessActivate from '../Views/pages/account/signup_success_activate';
import SignUpSinginHeader from '../Views/Components/signup_signin_pages/header';
import SignUpSinginFooter from '../Views/Components/signup_signin_pages/footer';
import BackofficeHeader from '../Views/Components/backoffice/header';
import BackofficeFooter from '../Views/Components/backoffice/footer';
import Backoffice from '../Views/pages/backoffice/backoffice'
import EditUser from '../Views/pages/backoffice/edituser'
import jwt_decode from "jwt-decode";
import Confirmationbooking from "../Views/pages/booking/bookingconfirm";
import {Helmet} from "react-helmet";

function mapStateToProps(state) {
  return {
    data: state.reducer,
    user: state.userReducer
  }
}

const mapDispatchToProps = {
  LOADER,
  LOGEDUSER,
  DEVISE,
};

/* ROUTE COMPONENT */

class Routes extends Component {
  constructor() {
    super();
    this.picker2 = React.createRef();
    this.state = {
      date: [],
      data: { issues: [] },
      params: window.location.search.slice(1).split('&').reduce((acc, s) => {
        const [k, v] = s.split('=')
        return Object.assign(acc, { [k]: v })
      }, {}),
      redirect: false,
    };
  }
  async componentDidMount() {
    var token = localStorage.getItem('token');
    if (token) {
      var decoded = jwt_decode(token);
      if (decoded.exp * 1000 < Date.now()) {
        localStorage.removeItem('token');
      } else {
        this.props.LOGEDUSER({ "username": decoded.username, "email": decoded.email, "roles": decoded.roles, "loged": true, "phone": decoded.phone, "name": decoded.name, "address": decoded.address, "country": decoded.Country, "city": decoded.City, "company": decoded.company, "deposit": decoded.deposit });
        if (decoded.roles.indexOf("ROLE_PARTICULAR")==-1){
          this.props.DEVISE(decoded.currency);
        }
      }
    }
   await fetch(`${process.env.REACT_APP_API_KEY_PROD}api/HotelInfo/${this.state.params.hotelid}`)
      .then(function (response) {
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then(
        (result) => {
          this.setState({ data: result[0] });
          this.props.LOADER(false);
        },
        (error) => {
          this.setState({ redirect: true });
        }
      )
  }

  logout = () => {
    localStorage.removeItem('token');
    window.location.href = `/?hotelid=${this.state.params.hotelid}`;
  }

  render() {
   
    if (this.state.redirect) {
      return (
        <Switch>
          <Route exact path='/404' component={NotFound} />
          <Redirect from='*' to='/404' />
        </Switch>
      )
    }
    return (
     
      <div className="main-page">
        <Helmet>
            <title>{this.state.data.name}</title>
            <link rel="icon" type="image/png" href={process.env.REACT_APP_API_KEY_PROD+ this.state.data.logopath} sizes="16x16" />
        </Helmet>
        <Switch>
          <Route path='/confirmation' >
            <Route path="/" render={(props) => (
              <Confirmationbooking code={this.state.params.hotelid} />
            )} />
          </Route>
          <Route path='/Backoffice'>
            {this.props.data.loader ? <Loader /> : <BackofficeHeader currency={this.state.data.currency} logo={this.state.data.logopath} address={this.state.data.address ? this.state.data.address.address : ''} code={this.state.params.hotelid} user={this.props.user} logout={this.logout} />}
            <div className={this.props.data.loader ? "main-page-none" : "main-page"}>
              <Route exact path="/backoffice" render={(props) => (
                this.props.user.loged ? <Backoffice code={this.state.params.hotelid} /> : <Redirect to="/login"></Redirect>
              )} />
              <Route exact path="/backoffice/EditUser" render={(props) => (
                this.props.user.loged ? <EditUser code={this.state.params.hotelid} /> : <Redirect to="/login"></Redirect>
              )} />
              {this.props.data.loader ? <div></div> : <BackofficeFooter name={this.state.data.name} />}
            </div>
          </Route>
          <Route path='/login'>
            {this.props.data.loader ? <Loader /> : <SignUpSinginHeader currency={this.state.data.currency} logo={this.state.data.logopath} address={this.state.data.address ? this.state.data.address.address : ''} code={this.state.params.hotelid} />}
            <div className={this.props.data.loader ? "main-page-none" : "main-page"}>
              <Route exact path="/Login" render={(props) => (
                <LoginPage code={this.state.params.hotelid} />
              )} />
              {this.props.data.loader ? <div></div> : <SignUpSinginFooter name={this.state.data.name} />}
            </div>
          </Route>
          <Route path='/signup'>
            {this.props.data.loader ? <Loader /> : <SignUpSinginHeader  currency={this.state.data.currency} logo={this.state.data.logopath} address={this.state.data.address ? this.state.data.address.address : ''} code={this.state.params.hotelid} />}
            <div className={this.props.data.loader ? "main-page-none" : "main-page signup-container"}>
              <Route exact path="/Signup" render={(props) => (
                <Signup code={this.state.params.hotelid} user={this.props.user} />
              )} />
              <Route path="/Signup/Signupsuccess" render={(props) => (
                <Signupsuccess code={this.state.params.hotelid} />
              )} />
              <Route path="/Signup/SignupActivateAccount" render={(props) => (
                <SignupsuccessActivate code={this.state.params.hotelid} />
              )} />
              {this.props.data.loader ? <div></div> : <SignUpSinginFooter name={this.state.data.name} />}
            </div>
          </Route>
          <Route path='/account'>
            {this.props.data.loader ? <Loader /> : <SignUpSinginHeader  currency={this.state.data.currency} logo={this.state.data.logopath} address={this.state.data.address ? this.state.data.address.address : ''} code={this.state.params.hotelid} />}
            <div className={this.props.data.loader ? "main-page-none" : "main-page signup-container"}>
              <Route exact path="/account/Signupsuccess" render={(props) => (
                <Signupsuccess code={this.state.params.hotelid} />
              )} />
              <Route exact path="/account/forgetpassword" render={(props) => (
                <Forgetpassword code={this.state.params.hotelid} />
              )} />
              <Route exact path="/account/resetpassword" render={(props) => (
                <Resetpassword code={this.state.params.hotelid} />
              )} />
              {this.props.data.loader ? <div></div> : <SignUpSinginFooter name={this.state.data.name} />}
            </div>
          </Route>
          <Route path='/' >
            <Route>
              {this.props.data.loader ? <Loader /> : <Header currency={this.state.data.currency} logo={this.state.data.logopath} address={this.state.data.address ? this.state.data.address.address : ''} code={this.state.params.hotelid} user={this.props.user} logout={this.logout} />}
              <div className={this.props.data.loader ? "main-page-none" : "main-page"}>
                <Route exact path="/" render={(props) => (
                  <Home code={this.state.params.hotelid} />
                )} />
                <Route exact path="/choose-your-room" render={(props) => (
                  <Rooms code={this.state.params.hotelid} issues={this.state.data.issues} segments={this.state.data.length > 0 ? this.state.data.segmentCodes : []} />
                )} />
                <Route exact path="/booking" render={(props) => (
                  <Price code={this.state.params.hotelid} />
                )} />
                {/* <Route exact path="/confirmation" render={(props) => (
                  <Confirmation code={this.state.params.hotelid} />
                )} /> */}
              </div>
              {this.props.data.loader ? <div></div> : <Footer name={this.state.data.name} code={this.state.params.hotelid}/>}
            </Route>
          </Route>

        </Switch>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)

/* ROUTE COMPONENT */