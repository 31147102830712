import "../../../App.css";
import React, { Component } from "react";
import jwt_decode from "jwt-decode";

class SignupSuccess extends Component {

    constructor() {
        super();
        this.state={
            message:'',
            disabled:false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    resenedEmail = () => {

        window.scrollTo(0, 0);

        var self = this;

        var queryParams = new URLSearchParams(window.location.search)

        var token = queryParams.get("account")

        var decoded = jwt_decode(token);

        fetch(`${process.env.REACT_APP_API_KEY_PROD}api/ResendMailVerification/${decoded.email}/${token}/${this.props.code}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(function (response) {
                if (!response.ok) {
                    self.setState({ message: 'an error has accured' })
                    throw new Error("HTTP status " + response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                  if (result=="email has been sent"){
                     self.setState({message:result,disabled:true}) 
                  }
                },
                (error) => {

                }
            )
    }

    render() {
        return (
            <div className="SignupSuccess">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-4">
                            <h1>Sign Up</h1>
                        </div>
                        <div className="col-12 col-md-8 col-lg-8">
                            <div>
                                <p>Thank you for joining our Journeys. For security purposes, we ask that you verify your account to unlock the benefits that await you.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 text-center">
                    <h3>One more step!</h3>
                    <p>Please check your Email.</p>
                    {this.state.disabled ? '' : <u className="cursor-pointer" onClick={this.resenedEmail}>Resend verification e-mail</u>}
                    <p>{this.state.message}</p>
                </div>
            </div>
        );
    }
}
export default SignupSuccess;