import "../../App.css";
import React, { Component } from "react";
import "../../flatpicker.css";
import moment from 'moment';
import { ROOMSBOOKED, DEPARTING, ARRIVING, LOADER, NIGHTS, ROOMS } from '../../redux/actions';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    data: state.reducer
  }
}

const mapDispatchToProps = {
  ROOMSBOOKED,
  LOADER,
};

class Confirmation extends Component {
  constructor() {
    super();
    this.picker2 = React.createRef();
    this.state = {
      date: new Date(),
      total: 0,
    };
  }
  OpenDatePicker = () => {
    this.picker2.current.flatpickr.clear();
    this.picker2.current.flatpickr.open();
  };
  total = () => {
    var total = 0;
    this.props.data.roomsbooked.map((b, i) => (
      total=b.boards[0].apiprice
    ))

    return total;
  }
  render() {
    const { date } = this.state;
    return (
      <div className="App">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 sidebar_wrapper">
              <aside className="sidebar">
                <aside
                  id="hotelier-widget-booking-2"
                  className="widget widget--hotelier widget-booking"
                >
                  <h5 className="widget-title" >Your stay</h5>
                  <div className="widget-booking__wrapper">
                    <div className="widget-booking__dates d-flex justify-content-between">
                      <div className="widget-booking__date-block widget-booking__date-block--checkin">
                        <span className="widget-booking__date-label">Check-in</span>

                        <div className="widget-booking__date">
                          <span className="widget-booking__month-year">
                            {moment(this.props.data.arriving).format('MM')} {moment(this.props.data.arriving).format('YYYY')}
                          </span>
                          <span className="widget-booking__day">{moment(this.props.data.arriving).format('D')}</span>
                          <span className="widget-booking__day-name">{moment(this.props.data.arriving).format('ddd')}</span>
                        </div>
                      </div>

                      <div className="widget-booking__date-block widget-booking__date-block--checkout">
                        <span className="widget-booking__date-label">
                          Check-out
                        </span>
                        <div className="widget-booking__date">
                          <span className="widget-booking__month-year">
                            {moment(this.props.data.departing).format('MM')} {moment(this.props.data.departing).format('YYYY')}
                          </span>
                          <span className="widget-booking__day">{moment(this.props.data.departing).format('D')}</span>
                          <span className="widget-booking__day-name">{moment(this.props.data.departing).format('ddd')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
                <aside
                  id="hotelier-widget-rooms-filter-2"
                  className="widget widget--hotelier widget-rooms-filter"
                >
                  <h5 className="widget-title">Your Reservation</h5>
                  <div className="widget-rooms-filter__wrapper">
                    {this.props.data.roomsbooked.length >= 1 && <div className="yourreservation">
                      {this.props.data.roomsbooked.map((b, i) => (
                        <>
                          <div className="d-flex justify-content-between">
                            <p className="yourreservationdet">Room {i + 1}</p>
                            <p className="yourreservationdet">{b.boards[0].apiprice + " TND"}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="yourreservationdet">{b.nameRoom}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="yourreservationdet">{b.boards[0].description}</p>
                          </div>
                          <hr></hr>
                        </>
                      ))}
                    </div>}

                  </div>
                </aside>
              </aside>
            </div>
            <div className="col-8 col-md-8 col-lg-8  pad-5">
              <h4 className="section-header__title">
                Thank you. Your reservation has been received.
              </h4>

              <div className="reservation-received__section">
                <header className="section-header">
                  <h3 className="section-header__title mt-5">Reservation details</h3>
                </header>
             
                <table className="table table--reservation-table reservation-table hotelier-table">
                  <thead className="reservation-table__heading">
                    <tr className="reservation-table__row reservation-table__row--heading">
                      <th className="reservation-table__room-name reservation-table__room-name--heading">
                        Room
                      </th>
                      <th className="reservation-table__room-qty reservation-table__room-qty--heading">
                        Board
                      </th>
                      <th className="reservation-table__room-qty reservation-table__room-qty--heading">
                        Qty
                      </th>
                      <th className="reservation-table__room-cost reservation-table__room-cost--heading">
                        Cost
                      </th>
                    </tr>
                  </thead>
                  <tbody className="reservation-table__body">
                    {this.props.data.roomsbooked.map((b, i) => (
                      <tr className="reservation-table__row reservation-table__row--body">
                        <td className="reservation-table__room-name reservation-table__room-name--body">
                          <a
                            className="reservation-table__room-link"

                          >
                            {b.nameRoom}
                          </a>{" "}
                        </td>
                        <td className="reservation-table__room-qty reservation-table__room-qty--body">
                        {b.boards[0].description}
                        </td>
                        <td className="reservation-table__room-qty reservation-table__room-qty--body">
                          1
                        </td>
                        <td className="reservation-table__room-cost reservation-table__room-cost--body">
                          <span className="amount">{b.boards[0].apiprice + " TND"}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="reservation-table__footer">
                    <tr className="reservation-table__row reservation-table__row--footer">
                      <th
                        className="reservation-table__label reservation-table__label--total"
                        colspan="2"
                      >
                        Total Due:
                      </th>
                      <td className="reservation-table__data reservation-table__data--total">
                        <strong>
                          <span className="amount">{this.total}</span>
                        </strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div className="reservation-received__section">
                  <header className="section-header">
                    <h3 className="section-header__title">Guest details</h3>
                  </header>

                  <table className="table table--guest-details hotelier-table">
                    <tbody>
                      <tr className="reservation-table__row reservation-table__row--body">
                        <th className="reservation-table__label">Name:</th>
                        <td className="reservation-table__data">
                          {this.props.data.name}
                        </td>
                      </tr>

                      <tr className="reservation-table__row reservation-table__row--body">
                        <th className="reservation-table__label">Email:</th>
                        <td className="reservation-table__data">
                           {this.props.data.email}
                        </td>
                      </tr>

                      <tr className="reservation-table__row reservation-table__row--body">
                        <th className="reservation-table__label">Telephone:</th>
                        <td className="reservation-table__data">{this.props.data.phone}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="btn-primary-container calendar mt-5 mb-5">
                  <button
                    type="submit"
                    className="btn-primary style-1 button-stepper-availibity"
                    value=""
                  >
                    CANCEL RESERVATION
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Confirmation)