import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import SimpleReactValidator from 'simple-react-validator';
import jwt_decode from "jwt-decode";
import { Spinner, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

class ModalLogin extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({
            messages: {
                firstname: 'Some message',
            },
        });
        this.state = {
            email: '',
            user: '',
            password: '',
            errorlogin: '',
            clickedbutton: false,
        };
    }
    submitForm = (e) => {
        e.preventDefault();
        var self = this;
        if (this.validator.allValid()) {
            this.setState({
                clickedbutton: true,
            })
            fetch(`${process.env.REACT_APP_API_KEY_PROD}api/login_check`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": this.state.email,
                    "username": this.state.user,
                    "password": this.state.password
                })
            })
                .then(function (response) {
                    if (!response.ok) {
                        self.setState({ errorlogin: 'invalid credentials', clickedbutton: false })
                        throw new Error("HTTP status " + response.status);
                    }
                    return response.json();
                })
                .then(
                    (result) => {
                        var decoded = jwt_decode(result.token);
                        if (decoded.valid == "false") {
                            self.setState({ errorlogin: 'invalid credentials', clickedbutton: false, })
                        } else if (decoded.active == 0) {
                            self.setState({ errorlogin: 'Please Activate Your Account', clickedbutton: false, })
                        }else if ((decoded.roles.indexOf("ROLE_AGENT") == -1) && (decoded.roles.indexOf("ROLE_PARTICULAR") == -1) && (decoded.roles.indexOf("ROLE_AGENCY") == -1)){
                            self.setState({ errorlogin: 'invalid credentials', clickedbutton: false })
                        }
                        else {
                            localStorage.setItem('token', result.token);
                            window.location.reload();
                        }
                    },
                    (error) => {

                    }
                )
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    render() {
        return (
            <MDBContainer >
                <MDBModal isOpen={this.props.ModalLogin} toggle={this.props.toggle(1)} centered className='modal-markup'>
                    <MDBModalBody>
                    <div className="container">
                        <div className="row w-100">
                            <div className="col-12">
                                <div className="form form--login">
                                    <form className="js-form-gms-login">
                                        <div className="form__head">
                                            <div className="h5 form__title">Member Sign-In</div>
                                        </div>
                                        <div className="form__body">
                                            <p className="form__meta">* Required Fields</p>
                                            <div className="form__row">
                                                <label htmlFor="field-login-email" className="form__label">
                                                    Email Address *
                                                </label>
                                                <div className="form__controls">
                                                    <input
                                                        type="email"
                                                        className="field"
                                                        name="field-login-email"
                                                        id="field-login-email"
                                                        placeholder="Email Address *"
                                                        required=""
                                                        value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}
                                                    ></input>
                                                    {this.validator.message('email', this.state.email, 'required|email')}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-50 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        User *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            name="field-login-user"
                                                            id="field-login-user"
                                                            placeholder="User *"
                                                            required=""
                                                            value={this.state.user} onChange={(e) => this.setState({ user: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('user', this.state.user, 'required')}
                                                    </div>
                                                </div>
                                                <div className="form__row w-50">
                                                    <label className="form__label">
                                                        Password *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="password"
                                                            className="field"
                                                            name="field-login-password"
                                                            id="field-login-password"
                                                            placeholder="Password * "
                                                            required=""
                                                            value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('password', this.state.password, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__actions">
                                            {this.state.clickedbutton ?
                                                <Button disabled className="form__btn ">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button> :
                                                <button
                                                    type="submit"
                                                    className="form__btn "
                                                    value=""
                                                    onClick={(e) => this.submitForm(e)}
                                                >
                                                    SIGN IN
                                                </button>
                                            }
                                            <div class="srv-validation-message text-center">
                                                {this.state.errorlogin}
                                            </div>
                                            <NavLink 
                                                to={`signup/?hotelid=${this.props.code}`}
                                                className="forgot-password"
                                            >
                                                Not a Member ? Click here to create an account
                                            </NavLink>
                                            <br></br>
                                            <NavLink 
                                                to={`account/forgetpassword?hotelid=${this.props.code}`}
                                                className="forgot-password"
                                            >
                                                Forgot your password?
                                            </NavLink>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </MDBModalBody>
                </MDBModal>
            </MDBContainer>
        );
    }
}

export default ModalLogin;