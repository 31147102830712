import "../../../App.css";
import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import jwt_decode from "jwt-decode";
import { Spinner, Button } from 'react-bootstrap';
import { LOGEDUSER } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";

const mapDispatchToProps = {
    LOGEDUSER,
};

function mapStateToProps(state) {
    return {
        data: state.userReducer
    }
}

class Login extends Component {
    constructor() {
        super();
        this.validator = new SimpleReactValidator({
            messages: {
                firstname: 'Some message',
            },
        });
        this.state = {
            email: '',
            user: '',
            password: '',
            errorlogin: '',
            clickedbutton: false,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    submitForm = (e) => {
        e.preventDefault();
        var self = this;
        if (this.validator.allValid()) {
            this.setState({
                clickedbutton: true,
            })
            fetch(`${process.env.REACT_APP_API_KEY_PROD}api/login_check`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": this.state.email,
                    "username": this.state.user,
                    "password": this.state.password
                })
            })
                .then(function (response) {
                    if (!response.ok) {
                        self.setState({ errorlogin: 'invalid credentials', clickedbutton: false })
                        throw new Error("HTTP status " + response.status);
                    }
                    return response.json();
                })
                .then(
                    (result) => {
                     
                        var decoded = jwt_decode(result.token);
                        if (decoded.valid == "false") {
                            self.setState({ errorlogin: 'invalid credentials', clickedbutton: false, })
                        } else if (decoded.active == 0) {
                            self.setState({ errorlogin: 'Please Activate Your Account', clickedbutton: false, })
                        }else if ((decoded.roles.indexOf("ROLE_AGENT") == -1) && (decoded.roles.indexOf("ROLE_PARTICULAR") == -1) && (decoded.roles.indexOf("ROLE_AGENCY") == -1)){
                            self.setState({ errorlogin: 'invalid credentials', clickedbutton: false })
                        }
                        else {
                            localStorage.setItem('token', result.token);
                            setTimeout(() => {
                                
                              }, "1000")
                            window.location.href = `${process.env.REACT_APP_API_KEY_PROD_CURR}?hotelid=${this.props.code}`;
                        }
                    },
                    (error) => {

                    }
                )
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    render() {
        if (this.props.data.loged) return (
            <Redirect to={`/?hotelid=${this.props.code}`} />
        )
        return (
            <div className="LoginApp">
                <div className="open">
                    <div className="container login-row">
                        <div className="row w-100">
                            <div className="col-12 col-md-6 col-lg-6 border-l">
                                <div className="form form--login">
                                    <form className="js-form-gms-login">
                                        <div className="form__head">
                                            <div className="h5 form__title">Member Sign-In</div>
                                        </div>
                                        <div className="form__body">
                                            <p className="form__meta">* Required Fields</p>
                                            <div className="form__row">
                                                <label htmlFor="field-login-email" className="form__label">
                                                    Email Address *
                                                </label>
                                                <div className="form__controls">
                                                    <input
                                                        type="email"
                                                        className="field"
                                                        name="field-login-email"
                                                        id="field-login-email"
                                                        placeholder="Email Address *"
                                                        required=""
                                                        value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}
                                                    ></input>
                                                    {this.validator.message('email', this.state.email, 'required|email')}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="form__row w-50 mr-3">
                                                    <label htmlFor="field-login-email" className="form__label">
                                                        User *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="text"
                                                            className="field"
                                                            name="field-login-user"
                                                            id="field-login-user"
                                                            placeholder="User *"
                                                            required=""
                                                            value={this.state.user} onChange={(e) => this.setState({ user: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('user', this.state.user, 'required')}
                                                    </div>
                                                </div>
                                                <div className="form__row w-50">
                                                    <label className="form__label">
                                                        Password *
                                                    </label>
                                                    <div className="form__controls">
                                                        <input
                                                            type="password"
                                                            className="field"
                                                            name="field-login-password"
                                                            id="field-login-password"
                                                            placeholder="Password * "
                                                            required=""
                                                            value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })}
                                                        ></input>
                                                        {this.validator.message('password', this.state.password, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__actions">
                                            {this.state.clickedbutton ?
                                                <Button disabled className="form__btn ">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button> :
                                                <button
                                                    type="submit"
                                                    className="form__btn "
                                                    value=""
                                                    onClick={(e) => this.submitForm(e)}
                                                >
                                                    SIGN IN
                                                </button>
                                            }
                                            <div className="srv-validation-message text-center">
                                                {this.state.errorlogin}
                                            </div>
                                            <NavLink 
                                                to={`account/forgetpassword?hotelid=${this.props.code}`}
                                                className="forgot-password"
                                            >
                                                Forgot your password?
                                            </NavLink>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-6">
                                <div className="section__inner form--login">
                                    <div className="section__body">
                                        <div className="section__entry">
                                            <div
                                                className="h5 section__subtitle"
                                                id="title-id-23e23b47a1ccdef76d9d0c5705f2ed7f"
                                            >
                                                Become a  Journeys member
                                            </div>

                                            <p>
                                                Inspiring You To Go Further. That’s our commitment. And
                                                that’s what our Journeys, our exclusive recognition
                                                programme, is designed to do.
                                            </p>
                                        </div>
                                        <div className="loginicons">
                                            <div className="d-flex flex-column align-items-center">
                                                <i className="fas fa-piggy-bank"></i>
                                                <p>Best Rates Guarantee</p>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                                <i className="far fa-clock"></i>
                                                <p>Early Check-in & Late Check-out</p>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                                <i className="fas fa-wifi"></i>
                                                <p>Free Premium Wi-Fi</p>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                                <i className="fas fa-medal"></i>
                                                <p>TripAdvisor Awards</p>
                                            </div>
                                        </div>
                                        <NavLink to={`/signup?hotelid=${this.props.code}`}>
                                            <input
                                                type="submit"
                                                value="Become Partner"
                                                className="form__btn btn_bepartner"
                                            ></input>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)